import {Component} from "@angular/core";
import {
	BottomSheetMainComponent
} from "../../../components/bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {BottomSheetType} from "../../../components/bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-type";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {selectProductListLength} from "../../../state/product-list.selectors";
import {Observable} from "rxjs";

@Component({
	selector: "app-bottom-navbar",
	templateUrl: "./bottom-navbar.component.html",
	styleUrls: ["./bottom-navbar.component.css"]
})
export class BottomNavbarComponent {
	public badgeCount: Observable<number>;

	constructor(
		private _bottomSheet: MatBottomSheet,
		private store: Store<AppState>
	) {
		this.badgeCount = this.store.select(selectProductListLength);
	}

	/**
	 * This function opens the bottom sheet for manual input
	 */
	public openManualInputBottomSheet(): void {
		const bottomSheetRef = this._bottomSheet.open(BottomSheetMainComponent);
		bottomSheetRef.instance.loadComponent(BottomSheetType.MANUAL_INPUT);
	}
}
