<app-header></app-header>
<div class="main-body">
	<div class="status" [class.offline]="status === OnlineStatusType.OFFLINE">
		{{ status === OnlineStatusType.ONLINE ? 'Online' : 'Offline' }}
	</div>
	<app-loading-spinner *ngIf="spinner | async"></app-loading-spinner>
	<router-outlet></router-outlet>
</div>
<div class="footer">
	<app-bottom-navbar></app-bottom-navbar>
</div>
