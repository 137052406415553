<span *ngIf="componentType === type.NO_BASKET"
	  i18n="@@vbh.scanner.snackbar.no.basket">Der Warenkorb konnte nicht gefunden werden.
</span>
<span *ngIf="componentType === type.NO_GREEN_PRODUCT"
	  i18n="@@vbh.scanner.snackbar.no.green-product">Keine vorhandenen Produkte (grünes Symbol) wurden gefunden. Bearbeiten, Prüfen oder Fügen Sie neue Produkte hinzu.
</span>
<span *ngIf="componentType === type.NO_PRODUCT"
	  i18n="@@vbh.scanner.snackbar.no.product">Ein oder mehrere Artikel in der Artikelliste konnten nicht dem Warenkorb hinzugefügt werden.
</span>
<span *ngIf="componentType === type.ADD_TO_BASKET"
	  i18n="@@vbh.scanner.snackbar.add.to.basket">Die Artikel wurden dem Shop Warenkorb hinzugefügt.
</span>
<span *ngIf="componentType === type.NO_AUTH_TOKEN"
	  i18n="@@vbh.scanner.snackbar.no.auth.token">Melden Sie sich mit ihrem Konto an. Es konnte keine Autorisierung gefunden werden.
</span>
<span *ngIf="componentType === type.EDIT_NO_PRODUCT"
	  i18n="@@vbh.scanner.snackbar.edit.no.product">Der Artikel konnte nicht gefunden werden.
</span>
<span *ngIf="componentType === type.EDIT_SUCCESS_PRODUCT"
	  i18n="@@vbh.scanner.snackbar.edit.success.product">Der Artikel wurde erfolgreich aktualisiert.
</span>
<span *ngIf="componentType === type.EDIT_UPDATE_LIST"
	  i18n="@@vbh.scanner.snackbar.edit.update.list">Die Artikelliste wurde aktualisiert.
</span>
<span *ngIf="componentType === type.ADD_TO_LIST"
	  i18n="@@vbh.scanner.snackbar.add.to.list">Der Artikel wurde erfolgreich zur Artikelliste hinzugefügt.
</span>
<span *ngIf="componentType === type.ADD_TO_LIST_MAXIMUM"
	  i18n="@@vbh.scanner.snackbar.add.to.list.maximum">Der Artikel wurde erfolgreich zu einem bestehenden Artikel hinzugefügt. Die Menge kann nicht die maximale Menge von 9.999.999 überschreiten.
</span>
<span *ngIf="componentType === type.SETTINGS_SUCCESS"
	  i18n="@@vbh.scanner.snackbar.settings.success">Die Standardmenge wurde erfolgreich angepasst.
</span>
<span *ngIf="componentType === type.JUMP_AMOUNT_INFO"
	  i18n="@@vbh.scanner.snackbar.jump.amount.info">Beim Prüfen der Artikel wurde die Menge entsprechend der Sprungmenge angepasst.
</span>
