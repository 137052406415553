import {ErrorHandler, Injectable} from "@angular/core";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class ProductErrorHandler implements ErrorHandler {
	public handleError(error: any): void {

		let errorMessage: string;
		if (error instanceof HttpErrorResponse) {
			// Unable to find product
			errorMessage = "Server error or product not found";
		} else {
			errorMessage = error.message;
		}
		throw new Error(errorMessage);
	}
}
