import {Injectable} from "@angular/core";

/**
 * Handles the rotation and set of the images.
 */
@Injectable({
	providedIn: "root"
})
export class ImageCarouselService {
	public static readonly NO_IMAGE: string = "assets/img-not-available.png";

	constructor() {
	}

	/**
	 * Checks if the image is available
	 * @param imageUrls List of images
	 * @param index Index of the first element
	 * @return Returns image url of the first url or a default image path
	 */
	checkImage(imageUrls: string[], index: number): string {
		if (imageUrls == undefined || imageUrls[index] == undefined) {
			return ImageCarouselService.NO_IMAGE;
		}
		return imageUrls[index];
	}


	/**
	 * Rotate the images like a carousel
	 * @param imageUrls List of images
	 * @param index Current index of the acutal image in the list
	 * @return Next images and index or first image and first index
	 */
	public changeImage(imageUrls: string[], index: number): [string, number] {
		if (imageUrls[++index] != undefined) {
			return [imageUrls[index], index];
		} else if (imageUrls[0] != undefined) {
			return [imageUrls[0], 0];
		} else {
			return [ImageCarouselService.NO_IMAGE, 0];
		}
	}
}
