import {createSelector} from "@ngrx/store";
import {AppState} from "./state";
import {ProductListBO} from "../components/product-scan-list/product-list-bo.model";
import {ExistStatus} from "../services/product/exist-status";
import {HeaderType} from "../shell/shared/header/header-type";

// export const selectProductListState = createFeatureSelector<ReadonlyArray<ProductListBO[]>>("productListBO");
/** Reagiert wenn sich die state.productList irgendwie verändert:
 * this.productList = this.store.select(selectProductCollection); // Aktualisiert dann auch automatisch die this.productList
 **/
export const selectStateProductList = (state: AppState): ProductListBO[] => state.productList;
export const selectStateProductListLength = (state: AppState): number => state.productList.length;
export const selectStateSpinner = (state: AppState): boolean => state.spinner;
export const selectHeaderType = (state: AppState): HeaderType => state.headerType;


export const selectProductList = createSelector(
	selectStateProductList,
	(productList: ProductListBO[]): ProductListBO[] => {
		return productList;
	}
);

export const selectProductListLength = createSelector(
	selectStateProductListLength,
	(length: number): number => {
		return length;
	}
);

export const selectAllProductExist = createSelector(
	selectStateProductList,
	(productList: ProductListBO[]): boolean => {
		return productList.filter((product): boolean => product.existStatus === ExistStatus.UNKNOWN_GREY).length === 0;
	}
);

export const selectFindProduct = (sku: string): any => createSelector(
	selectStateProductList,
	(productList: ProductListBO[]): ProductListBO | undefined => {
		return productList.find((product: ProductListBO): any => product.sku === sku);
	}
);

export const selectSpinnerIsOn = createSelector(
	selectStateSpinner,
	(isOn: boolean): boolean => {
		return isOn;
	}
);

export const selectHeaderTypeScan = createSelector(
	selectHeaderType,
	(headerTypeScan: HeaderType): HeaderType => {
		return headerTypeScan;
	}
);

export const selectProductListIsEmpty = createSelector(
	selectStateProductListLength,
	(length: number): boolean => {
		return length == 0;
	}
);
