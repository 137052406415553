import {Component, Input, OnInit} from "@angular/core";
import {BottomSheetMainComponent} from "../../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {BottomSheetComponent} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-component";
import {ProductEditData} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/data/product-edit-data";
import {ProductListBO} from "../../product-scan-list/product-list-bo.model";
import {ImageCarouselService} from "../../../services/image/image-carousel.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ProductService} from "../../../services/product/product.service";
import {ProductListService} from "../../../services/product/product-list.service";
import {MatDialog} from "@angular/material/dialog";
import {DeleteConfirmationDialogComponent} from "./delete-confirmation-dialog/delete-confirmation-dialog.component";
import {ProductBO} from "../../../services/model/product/product-bo";
import {ExistStatus} from "../../../services/product/exist-status";
import {Router} from "@angular/router";
import {ProductQuantityService} from "../../../services/quantity/product-quantity.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {startSpinner, stopSpinner} from "../../../state/product-list.actions";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {SnackbarType} from "../../snackbar/snackbar-type";
import {selectProductList} from "../../../state/product-list.selectors";
import {VariationDropdownData} from "./variation-dropdown-data";
import {ProductVariationBO} from "../../../services/model/product/variation/product-variation-bo";
import {VariationBO} from "../../../services/model/product/variation-bo";
import {VariationDropdown} from "./variation-dropdown";
import {OnlineStatusService, OnlineStatusType} from "ngx-online-status";
import {VariationCombination} from "./variation-combination";
import {concatMap, Observable} from "rxjs";

@Component({
	selector: "app-bottom-sheet-edit-scan-list",
	templateUrl: "./bottom-sheet-edit-scan-list.component.html",
	styleUrls: ["./bottom-sheet-edit-scan-list.component.css"]
})
export class BottomSheetEditScanListComponent implements BottomSheetComponent, OnInit {
	@Input() imageUrl: string = "";
	public oldSku: string = "";
	public productListBo: ProductListBO = {
		imageUrls: [],
		minQuantity: 0,
		name: "",
		barcode: "",
		sku: "",
		quantity: "0",
		existStatus: ExistStatus.EXIST_GREEN
	};

	public status: OnlineStatusType = this.onlineStatusService.getStatus();
	public dropdownList: VariationDropdown[] = [];
	public productList: ProductListBO[] = [];
	public data: ProductEditData = {productListBo: this.productListBo};
	public formValidator: UntypedFormGroup = new UntypedFormGroup({
		quantityFormInput: new UntypedFormControl("", Validators.compose(
			[Validators.minLength(1),
				Validators.maxLength(13),
				Validators.pattern("^[^a-zA-Z]+$"),
				Validators.required])),
		skuInput: new UntypedFormControl("", Validators.compose(
			[Validators.required, Validators.maxLength(20),]
		))
	});

	constructor(
		public bottomSheetMainComponent: BottomSheetMainComponent,
		private imageService: ImageCarouselService,
		private productService: ProductService,
		public productListService: ProductListService,
		public dialog: MatDialog,
		public router: Router,
		private store: Store<AppState>,
		private quantityService: ProductQuantityService,
		private snackbarService: SnackbarService,
		private onlineStatusService: OnlineStatusService,
	) {
		this.store.select(selectProductList).subscribe((list: ProductListBO[]): any => {
			this.productList = list;
		});

		this.onlineStatusService.status.subscribe((status: OnlineStatusType): any => {
			// use status
			this.status = status;
		});
	}

	public get form(): any {
		return this.formValidator.controls;
	}

	public get formSkuValue(): string {
		return this.formValidator.value.skuInput;
	}

	public get formQuantityValue(): string {
		return this.formValidator.value.quantityFormInput;
	}

	public ngOnInit(): void {
		this.productListBo.imageUrls = this.data.productListBo.imageUrls;
		this.productListBo.minQuantity = this.data.productListBo.minQuantity;
		this.productListBo.name = this.data.productListBo.name;
		this.productListBo.sku = this.data.productListBo.sku;
		this.productListBo.quantity = this.data.productListBo.quantity;
		this.productListBo.existStatus = this.data.productListBo.existStatus;
		this.productListBo.productMasterSKU = this.data.productListBo.productMasterSKU;
		if (this.data.productListBo.variation != undefined) {
			this.productListBo.variation = this.mapVariationList(this.data.productListBo.variation);
		}
		this.imageUrl = this.imageService.checkImage(this.productListBo.imageUrls, 0);
		this.oldSku = this.productListBo.sku;
		this.formValidator.controls.skuInput.setValue(this.productListBo.sku);
		this.createDropdownValues();
	}

	/**
	 * Edit product from the localstorage
	 */
	public editProduct(): void {
		this.store.dispatch(startSpinner());
		let editProductBo: ProductListBO = {
			imageUrls: this.productListBo.imageUrls,
			minQuantity: this.productListBo.minQuantity,
			name: this.productListBo.name,
			sku: this.productListBo.sku,
			barcode: this.formValidator.value.skuInput,
			quantity: this.formValidator.value.quantityFormInput,
			existStatus: this.productListBo.existStatus,
		};

		if (this.productListBo.variation != undefined) {
			editProductBo.productMasterSKU = this.productListBo.productMasterSKU;
			editProductBo.variation = this.mapVariationList(this.productListBo.variation);
		}
		let isMaximum = this.productListService.editProduct(this.oldSku, editProductBo, this.productList);
		if (isMaximum) {

			this.snackbarService.openSnackbar(SnackbarType.ADD_TO_LIST_MAXIMUM);
		} else {
			this.snackbarService.openSnackbar(SnackbarType.EDIT_UPDATE_LIST);
		}
		this.bottomSheetMainComponent.closeBottomSheet();
	}

	public update(): void {
		this.store.dispatch(startSpinner());
		if (!navigator.onLine) {
			this.productListBo.sku = this.formValidator.value.skuInput;
			this.productListBo.barcode = this.formValidator.value.skuInput;
			this.productListBo.quantity = this.formValidator.value.quantityFormInput;
			this.productListBo.name = "";
			this.productListBo.minQuantity = 1;
			this.productListBo.imageUrls = [];
			this.imageUrl = this.imageService.checkImage(this.productListBo.imageUrls, 0);
			this.productListBo.existStatus = ExistStatus.UNKNOWN_GREY;
			this.snackbarService.openSnackbar(SnackbarType.EDIT_SUCCESS_PRODUCT);
			this.productListBo.productMasterSKU = undefined;
			this.productListBo.variation = undefined;
			this.dropdownList = [];
			this.store.dispatch(stopSpinner());
			return;
		}

		this.productService.getProductInformationViaSearchTerm(this.formValidator.value.skuInput).pipe(
			concatMap((sku: string): Observable<ProductBO> => {
				return this.productService.getProductInformation(sku);
			})).subscribe(
			{
				next: (product: ProductBO): any => {
					this.productListBo.name = product.name;
					this.productListBo.sku = product.sku;
					this.productListBo.minQuantity = product.minOrderQuantity;
					this.productListBo.imageUrls = product.imageUrls;
					this.productListBo.barcode = this.formValidator.value.skuInput;
					let quantity = this.quantityService.calculateQuantity(this.productListBo.quantity, product.minOrderQuantity);

					this.productListBo.checkJumpAmount = {
						oldQuantity: this.productListBo.quantity,
						hasJumpAmount: quantity.jumpAmount
					};
					this.productListBo.quantity = quantity.value;
					this.productListBo.productMasterSKU = product.productMasterSKU;
					this.productListBo.variation = product.variation;
					this.createDropdownValues();

					if (quantity.jumpAmount) {
						this.formValidator.controls.quantityFormInput.setValue(this.productListBo.quantity);
					} else {
						this.formValidator.controls.quantityFormInput.setValue(this.formValidator.value.quantityFormInput);
					}

					this.formValidator.controls.quantityFormInput.markAsPending();

					this.imageUrl = product.imageUrls[0];
					this.productListBo.existStatus = ExistStatus.EXIST_GREEN;
					this.snackbarService.openSnackbar(SnackbarType.EDIT_SUCCESS_PRODUCT);
				},
				error: (): void => {
					this.productListBo.barcode = undefined;
					this.productListBo.name = "Fehlerhafter Artikel";
					this.productListBo.productMasterSKU = undefined;
					this.productListBo.variation = undefined;
					this.createDropdownValues();
					this.productListBo.imageUrls = [];
					this.imageUrl = ImageCarouselService.NO_IMAGE;
					this.productListBo.existStatus = ExistStatus.NOT_EXIST_RED;
					this.snackbarService.openSnackbar(SnackbarType.EDIT_NO_PRODUCT);
				}
			});
		this.store.dispatch(stopSpinner());
	}

	public openDialog(): void {
		this.dialog.open(DeleteConfirmationDialogComponent, {
			data: {
				sku: this.productListBo.sku
			}
		}).afterClosed()
			.subscribe((): any => {
				this.bottomSheetMainComponent.closeBottomSheet();
			});
	}

	/**
	 * Will be executed when the dropdown value is changed
	 * @param type Type of the dropdown
	 * @param value Contains the value of the dropdown
	 */
	public dropdownSelectionChange(type: string, value: string): void {
		let variationDropdown = this.dropdownList.find((dropdownList: VariationDropdown): any => dropdownList.type === type);
		if (variationDropdown) {
			let uri = variationDropdown.data.find((dropdownItem: VariationDropdownData): boolean => dropdownItem.value === value);

			if (uri) {
				this.changeProductVariation(type, value, uri.uri);
			}
		}
	}


	/***
	 * Find the correct product for the variant and if not select the most fitting product.
	 * @param type Type of the dropdown
	 * @param value The actual value of the dropdown
	 * @param uris Uris of the value
	 */
	changeProductVariation(type: string, value: string, uris: string[]): void {
		if (navigator.onLine) {
			if (uris.length === 1) {
				// call api refresh values for the new product
				this.formValidator.controls.skuInput.setValue(uris[0].substring("VBHDE-VBH_24-Site/-/products/".length));
				this.update();
			} else {
				let notChangedDropdownsList = this.dropdownList.filter((dropdownList: VariationDropdown): any => dropdownList.type !== type);
				let allowUriList: string[] = uris;
				let endUri = "";
				notChangedDropdownsList.forEach((list: VariationDropdown): void => {
					// Get uris of the actual dropdown menus
					let variationData: VariationDropdownData | undefined = list.data.find((variationData: VariationDropdownData): boolean => variationData.value === list.selectedValue.value);
					uris.forEach((uri: string): void => {
						if (variationData != undefined) {
							let allowUri = variationData.uri.find((varUri: string): boolean => varUri === uri);
							if (allowUri != undefined) {
								allowUriList.push(allowUri);
							}
						}
					});
					endUri = this.getMostFittingUri(uris, allowUriList);
				});
				this.changeProductVariation(type, value, [endUri]);
			}
		}
	}

	private getMostFittingUri(uris: string[], allowUriList: string[]): string {
		let endUri = "";
		let length = 0;
		uris.forEach((uriOriginal: string): void => {
			let uriListLength = allowUriList.filter((allowUri: string): any => allowUri === uriOriginal).length;
			if (uriListLength > length) {
				length = uriListLength;
				endUri = uriOriginal;
			}
		});
		return endUri;
	}

	private mapVariationList(variations: VariationBO[]): VariationBO[] {
		let list: VariationBO[] = [];
		variations.forEach((variation: VariationBO): void => {
			let bo: VariationBO = {
				name: variation.name,
				value: variation.value
			};
			list.push(bo);
		});
		return list;
	}

	private createDropdownValues(): any {
		if (this.productListBo.productMasterSKU != undefined && this.productListBo.variation != undefined) {
			this.dropdownList = this.initialDropdownListType(this.productListBo.variation);
			this.productService.getProductVariation(this.productListBo.productMasterSKU).subscribe((value: ProductVariationBO[]): any => {
				// Fill list with data
				value.forEach((value1: ProductVariationBO): void => {
					value1.elements.forEach((value2: VariationBO): void => {
						let listObj: VariationDropdownData = {uri: [], value: "", differentCombination: false};
						listObj.uri.push(value1.uri);
						listObj.value = value2.value;

						this.dropdownList.filter((a: VariationDropdown): any => a.type === value2.name).forEach((e: VariationDropdown): void => {
							let variationData: VariationDropdownData | undefined = e.data.find((ob: VariationDropdownData): boolean => ob.value === value2.value);
							if (variationData === undefined) {
								e.data.push(listObj);
							} else {
								variationData.uri.push(value1.uri);
							}
						});
					});
				});
				this.setCombinationInDropdown(value);
			});
		} else {
			this.dropdownList = [];
		}
	}

	private setCombinationInDropdown(value: ProductVariationBO[]): void {
		let listOne: VariationCombination[][] = [];
		let listMultiply: VariationCombination[][] = [];

		// Calculate difference between all values
		value.forEach((productVariationBO: ProductVariationBO): void => {
			let combinations: VariationCombination[] = [];
			productVariationBO.elements.forEach((variationBO: VariationBO): void => {
				let variationCombination: VariationCombination = {
					name: variationBO.name,
					value: variationBO.value,
					combination: true
				};

				let dropdown = this.dropdownList.find((variationDropdown: VariationDropdown): boolean => variationDropdown.selectedValue.name === variationBO.name && variationDropdown.selectedValue.value === variationBO.value);
				if (dropdown === undefined) {
					variationCombination.combination = false;
				}
				combinations.push(variationCombination);
			});

			// Add combination list to the different types
			let combinationList = combinations.filter((variation: VariationCombination): boolean => !variation.combination);
			let falsy: number = combinationList.length;
			// List multiply contains products that are available with a different combination
			if (falsy === 1) {
				listOne.push(combinationList);
			}
			// List multiply contains products that only differs by one attribute, and are so no different combination
			if (falsy > 1) {
				listMultiply.push(combinationList);
			}
		});

		// Set the style in the dropdown menu
		this.setCombinationForDropdown(listMultiply, true);
		this.setCombinationForDropdown(listOne, false);
	}

	private setCombinationForDropdown(combinationMatrix: VariationCombination[][], hasCombination: boolean): void {
		combinationMatrix.forEach((element: VariationCombination[]): void => {
			element.forEach((variationCombination: VariationCombination): void => {
				this.dropdownList.forEach((dropdown: VariationDropdown): void => {
					if (dropdown.type === variationCombination.name) {
						let variationDropdownData = dropdown.data.find((dropdownData: VariationDropdownData): boolean => variationCombination.value === dropdownData.value);
						if (variationDropdownData !== undefined) {
							variationDropdownData.differentCombination = hasCombination;
						}
					}
				});
			});
		});
	}

	private initialDropdownListType(variation: VariationBO[]): VariationDropdown[] {
		return variation.map((productVariation: VariationBO): VariationDropdown => {
			return {
				type: productVariation.name,
				selectedValue: productVariation,
				data: []
			} as VariationDropdown;
		});
	}
}
