import {Component, Input, OnInit} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {ProductQuantityService} from "../../services/quantity/product-quantity.service";
import {Quantity} from "../../services/quantity/quantity";
import {CheckJumpAmount} from "../product-scan-list/check-jump-amount";

@Component({
	selector: "app-quantity",
	templateUrl: "./quantity.component.html",
	styleUrls: ["./quantity.component.css"]
})
export class QuantityComponent implements OnInit {
	public jumpAmount: boolean = false;
	public isMinimum: boolean = false;
	public isMaximum: boolean = false;
	@Input() public minQuantity: number = 0;
	@Input() public startValue: string | undefined;
	@Input() public parentForm: UntypedFormGroup = new UntypedFormGroup({});
	@Input() public checkJumpAmount?: CheckJumpAmount | undefined;
	// Timeout config
	private timerForQuantityInput: any = null;

	constructor(
		public quantityService: ProductQuantityService
	) {
	}

	public get quantityInputControl(): any {
		return this.parentForm.controls;
	}

	public ngOnInit(): void {
		if (this.startValue !== undefined) {
			let quantityValue = this.quantityService.calculateQuantity(this.startValue, this.minQuantity);
			this.setQuantityAndJumpAmount(quantityValue);
			return;
		}
		let quantityValue = this.quantityService.getDefaultQuantitySettings(this.minQuantity);
		this.setQuantityAndJumpAmount(quantityValue);
	}

	/**
	 * Reacts on user input into the quantity input field
	 * @param event Value of the input
	 */
	public onInput(event: any): void {
		clearTimeout(this.timerForQuantityInput);
		if (this.parentForm.controls.quantityFormInput.valid) {
			this.parentForm.controls.quantityFormInput.setErrors({"incorrect": true});
		}
		this.timerForQuantityInput = setTimeout((): any => {
			// Calculates if the input fits into the quantity jump amount
			let quantityValue = this.quantityService.calculateQuantity(event.target.value, this.minQuantity);
			this.setQuantityAndJumpAmount(quantityValue);
			this.parentForm.controls.quantityFormInput.setErrors(null);
		}, 1000);
	}

	/**
	 * Add min quantity to the actual value
	 */
	public plusQuantity(): void {
		let quantityValue = this.quantityService.plusQuantity(this.parentForm.value.quantityFormInput, this.minQuantity);
		this.setQuantityAndJumpAmount(quantityValue);
	}

	/**
	 * Subtract min quantity to the actual value
	 */
	public minusQuantity(): void {
		let quantityValue = this.quantityService.minusQuantity(this.parentForm.value.quantityFormInput, this.minQuantity);
		this.setQuantityAndJumpAmount(quantityValue);
	}

	/**
	 * Set the values for the quantity
	 * @param quantityValue
	 * @private
	 */
	private setQuantityAndJumpAmount(quantityValue: Quantity): void {
		this.parentForm.controls.quantityFormInput.setValue(quantityValue.value);
		this.checkJumpAmount = undefined;
		this.jumpAmount = quantityValue.jumpAmount;
		this.isMinimum = quantityValue.isMinimum;
		this.isMaximum = quantityValue.isMaximum;
	}
}
