import {Component, Inject} from "@angular/core";
import {ProductListService} from "../../../../services/product/product-list.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {startSpinner} from "../../../../state/product-list.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../state/state";

@Component({
	selector: "app-delete-confirmation-dialog",
	templateUrl: "./delete-confirmation-dialog.component.html",
	styleUrls: ["./delete-confirmation-dialog.component.css"]
})
export class DeleteConfirmationDialogComponent {
	constructor(
		public productListService: ProductListService,
		public dialogRef: MatDialogRef<DeleteConfirmationDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private store: Store<AppState>
	) {
	}

	/**
	 * Delete product from the localstorage
	 */
	public deleteProduct(): void {
		this.store.dispatch(startSpinner());
		this.productListService.deleteProduct(this.data.sku);
		this.closeDeleteDialog();
	}

	public closeDeleteDialog(): void {
		this.dialogRef.close();
	}

}
