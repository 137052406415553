<div class="container">
	<span aria-hidden="true" class="bi bi-x-circle"></span>
	<p i18n="@@vbh.scanner.bs.confirmation.fail.article.not.found">Der Artikel wurde nicht gefunden!</p>
</div>

<div class="modal-footer">
	<button (click)="toManualInput()"
			matRipple
			class="btn secondary-btn btn-lg shadow-sm col-6"
			i18n="@@vbh.scanner.bs.confirmation.fail.search">Suche
	</button>
	<button (click)="rescan()"
			matRipple
			class="btn primary-btn btn-lg shadow-sm col-6"
			i18n="@@vbh.scanner.bs.confirmation.fail.scan.again">Erneut scannen
	</button>
</div>
