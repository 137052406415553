<form [formGroup]="formValidator" data-test="rescan-btn">
	<div class="form-group">
		<label data-test="rescan-btn"
			   class="lato-regular"
			   for="skuInput"
			   i18n="@@vbh.scanner.bs.manual.input.article.number">Artikelnummer
			<span (click)="openDialog()" class="bi bi-question-circle" data-test="help-dialog"></span>
		</label>
		<input class="form-control lato-regular"
			   formControlName="skuInput"
			   id="skuInput"
			   placeholder="z.B. 2172714674"
			   type="text"
			   data-test="sku-input"
		>
		<div *ngIf="form.skuInput.touched && form.skuInput.invalid">
			<div *ngIf="form.skuInput.errors?.required"
				 class="alert alert-info lato-regular"
				 i18n="@@vbh.scanner.bs.manual.input.validation.insert.sku">Geben Sie eine gültige Artikelnummer ein.
			</div>
			<div *ngIf="form.skuInput.errors?.pattern"
				 class="alert alert-danger lato-regular"
				 i18n="@@vbh.scanner.bs.manual.input.validation.sku">Eine Artikelnummer besteht aus mindestens 10
				Zahlen.
			</div>
		</div>
	</div>
</form>
<div class="modal-footer">
	<button (click)="rescan()"
			matRipple
			class="btn secondary-btn btn-lg shadow-sm col-6 lato-regular"
			data-test="rescan-btn"
			i18n="@@vbh.scanner.bs.manual.input.scan.again">Erneut scannen
	</button>
	<button (click)="search()"
			[disabled]="!formValidator.valid"
			matRipple
			class="btn primary-btn btn-lg shadow-sm col-6 lato-regular"
			data-test="search-btn"
			type="submit"
			i18n="@@vbh.scanner.bs.manual.input.search">Suchen
	</button>
</div>
