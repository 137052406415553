// default environment configuration

declare var require: any;

export const environment = {
	appVersion: require("../../package.json").version,
	production: false,
	// pipeUrl: "https://localhost:8443/INTERSHOP/web/WFS",
	// restUrl: "https://localhost:8443/INTERSHOP/rest/WFS",
	// baseUrl: "https://localhost:8443/INTERSHOP/rest/WFS" + "/VBHDE-VBH_24-Site/-",
	imageUrl: "https://vbh24.de",
	staticPath:"https://vbh24.de/INTERSHOP/static/WFS/VBHDE-VBH_24-Site/-/VBHDE-Site/de_DE/",
	pipeUrl: "https://vbh24.de/INTERSHOP/web/WFS",
	restUrl: "https://api.vbh24.de/INTERSHOP/rest/WFS",
	baseUrl: "https://vbh24.de/INTERSHOP/rest/WFS" + "/VBHDE-VBH_24-Site/-",
	storefrontUrl: "https://vbh24.de/INTERSHOP/web/WFS/VBHDE-VBH_24-Site/de_DE/smb-responsive/EUR/ViewHomepage-Start",
	scanditLicenseKey: "ATNEFIWBEa4GAlYNAyfnmqkDOXnXDHZNAhaQcVxSChh1cpJjFWLyGyYo+l4wRDIfDjQHMkByiurvXiKRHmpAbTlpyb9bdAMl70X4b5E8pYWIL4EQfTXCNQJFuoLicCnX8zNxRPUx4dX3dnBN6FHwcKVUT4GeKUs1AD9waKMOo1GsHJ8xbBZc1KlXrj43DUkopXKN8Fle0vMQOLQH6//t3jPxIR020pAN6ydG+qhDAUw0boB56loFya6+9jP5+ykqXjmfNBjWE1ZgMuVs0kVLXvh5QGDqwGe42ZL36YTBg36CeeOAv0P32F5RjOoeuLhQcQ8XA54XUplpDQ6LrbI7K/7wKmVt+dwdoBK+h04PWMI9Y+GCeXEmZRQZx11kF1+vkRxZRueaHayONeyPIz4MohUbUS9D9Aza3ugs87omJHW2MM+VlTtl1YVEeLtdnSgzuH9YtE3GrVm8/0HCXL4BjaxnChZSZSpl140eHovhNtotWSW3iYZN2XDCyJ2DgBu2Ljby8b7LaNxPN2R2SH7qtaAgoTUyqWYryROyjH7X76jZLaWE/UJ2aidHKwnycG92ZJ/Y6kVuSMdZY3lPjD1ZySFs8H4Ed1aGT0Es3OXwtiYAqSHYED/5uyGsaRa5zt8/KdP0qiTMdth4c+a8Gz5qakkO1OwIduyWXzPUbq9sQX7U2kCEuNIOtZzjIgq4oJxU/ld4M4rDi18OJyZ6tRzYMu29cO3szhqRws5zEIvRmoe+U+LnKEH/D1tCJh5M1Ey5oDVlFujxwILc/ZVmYE/7L8PlcQjucv8sx5uR+vaDsMAaBKs6lwRnhSc5k3d+W+fLExd2+K/0fZTgY0ZUjl78XZhQrbbpHg==",
	MAX_HIGH_VALUE: 9999999,
	middlewareApiUrl: "https://rest-search-query-api-e5e24b7aa353.herokuapp.com/api/fetchCombinedList"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
