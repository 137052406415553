import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ProductErrorHandler} from "../error-handling/product-error-handler";
import {Observable, catchError, map} from "rxjs";
import {environment} from "src/environments/environment";
import {AppSettingsService} from "src/app/components/settings/app-settings.service";
import {ProductBO} from "../model/product/product-bo";

@Injectable({
	providedIn: "root"
})
export class MiddlewareService {

	constructor(private http: HttpClient, private errorHandler: ProductErrorHandler, private settingsService: AppSettingsService) {
	}

	sendMiddlewareRequest(searchTerm: any): Observable<ProductBO[]> {
		return this.http.post<any>(environment.middlewareApiUrl, {
			"searchTerm": searchTerm.defaultCategory,
			"sku": searchTerm.sku,
			"temperature": this.settingsService.settings.chatGptTemperature,
			"modelKey": this.settingsService.settings.chatGptModel,
			"promptKey": this.settingsService.settings.chatGptPromptKey
		}).pipe(map(
			(response: ProductBO[]) => {
				response.map((product) => product.imageUrls = product.imageUrls.map((imageUrl) => environment.staticPath + imageUrl));
				return response;

			}
		),
		catchError((err) => {
			throw this.errorHandler.handleError(err);
		})
		);
	}
}
