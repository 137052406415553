<div>
	<h1 mat-dialog-title
		class="lato-regular"
		i18n="@@vbh.scanner.default.quantity.dialog.headline">
		<span class="icon-title bi bi-question-circle" aria-hidden="true"></span>
		Standardmenge
	</h1>
</div>
<div mat-dialog-content>
	<p i18n="@@vbh.scanner.default.quantity.dialog.text">
		Die Standardmenge gibt die Artikelmenge an, die bei jedem Scan und jeder Suche im Eingabefeld der
		Menge steht. Der Standardwert der Standardmenge ist 1.</p>
</div>
<div mat-dialog-actions>
	<button (click)="closeInfobox()"
			matRipple
			class="btn primary-btn btn-lg shadow-sm"
			data-test="close-btn"
			i18n="@@vbh.scanner.default.quantity.dialog.close">Schließen
	</button>
</div>
