import {Inject, Injectable, LOCALE_ID} from "@angular/core";
import {DecimalPipe} from "@angular/common";
import {AppSettingsService} from "../../components/settings/app-settings.service";
import {NumberToLocale} from "./locale/number-to-locale";
import {Quantity} from "./quantity";
import {environment} from "../../../environments/environment";

/**
 * This class has helper functions to handle the quantity input that needs to fit
 * to the correct jump amount.
 * Input value/Settings value: 10 Minquantity: 6
 * Output: 12 JumpAmount: true
 *
 * Input value/Settings value: 12 Minquantity: 6
 * Output: 12 JumpAmount: false
 */
@Injectable({
	providedIn: "root"
})
export class ProductQuantityService {

	constructor(
		private _decimalPipe: DecimalPipe,
		private settingsService: AppSettingsService,
		@Inject(LOCALE_ID) public locale: string
	) {
	}

	/**
	 * Used for the initialization of the quantity that the settings quantity fits into the jump amount
	 * @param minQuantity Min quantity of the product
	 * @return Returns quantity object.
	 */
	public getDefaultQuantitySettings(minQuantity: number): Quantity {
		return this.calculateQuantity(this.settingsService.settings.defaultAmount.toString(), minQuantity);
	}

	/**
	 * Used for the increment button, to go one step (minQuantity) up
	 * @param quantity
	 * @param minQuantity
	 * @return Returns quantity object.
	 */
	public plusQuantity(quantity: string, minQuantity: number): Quantity {
		let value: number = this.checkInput(quantity);
		let va = ((value * 1000) + (minQuantity * 1000)) / 1000;
		let quantityValue = this.calculateModuloForQuantityAndJumpAmount(va, minQuantity);
		quantityValue.jumpAmount = false;
		return quantityValue;
	}

	/**
	 * Used for the decrement button, to go one step (minQuantity) down
	 * @param quantity
	 * @param minQuantity
	 * @return Returns correct quantity and jump amount.
	 */
	public minusQuantity(quantity: string, minQuantity: number): Quantity {
		let value: number = this.checkInput(quantity);
		let va = ((value * 1000) - (minQuantity * 1000)) / 1000;
		let quantityValue = this.calculateModuloForQuantityAndJumpAmount(va, minQuantity);
		quantityValue.jumpAmount = false;
		return quantityValue;
	}

	/**
	 * 1. Checks the input value if it is correct (no text, a number)
	 * 2. Check if the jump amount fits
	 * @param quantity Value of the quantity input field
	 * @param minQuantity Min quantity from the product
	 * @return Returns quantity object
	 */
	public calculateQuantity(quantity: string, minQuantity: number): Quantity {
		let value = this.checkInput(quantity);
		return this.calculateModuloForQuantityAndJumpAmount(value, minQuantity);
	}

	/**
	 * Calculates the addition form two numbers.
	 * @param quantity1
	 * @param quantity2
	 */
	public addQuantity(quantity1: string, quantity2: string, minQuantity: number): Quantity {
		let num1 = NumberToLocale.getNumber(quantity1, this.locale);
		let num2 = NumberToLocale.getNumber(quantity2, this.locale);
		if (Number.isNaN(num1) || num1 == null || Number.isNaN(num2) || num2 == null) {
			throw new Error("No correct addition possible");
		}

		let endValue = ((num1 * 1000) + (num2 * 1000)) / 1000;
		if (endValue > environment.MAX_HIGH_VALUE) {
			endValue = ((environment.MAX_HIGH_VALUE * 1000) - ((environment.MAX_HIGH_VALUE * 1000) % (minQuantity * 1000))) / 1000;
		}
		let valueString = this.transformNumberToI18nString(endValue);
		return {value: valueString, jumpAmount: false, isMinimum: false, isMaximum: true} as Quantity;
	}

	/**
	 * Check if the @value is a number, if not return the min quantity
	 * Also turns i18n string into a number value
	 * @param value Quantity value from input field
	 * @private
	 * @return Returns quantity as a number or the min quantity
	 */
	public checkInput(value: string): number {
		let num = NumberToLocale.getNumber(value, this.locale);
		if (Number.isNaN(num) || num == null) {
			throw new Error("No correct addition possible");
		}


		return num;
	}

	/**
	 * Calculate the correct addition that the quantity needs to add
	 * when the actual num is not fitting into the jump amount.
	 * Sets jump amount when it is not fitting.
	 * @param quantity Unfitting or fitting quantity value
	 * @param minQuantity Min quantity of the product
	 * @private
	 * @return Return correct quantity, where a jump amount used and is the value equals the minQuantity (isMinimum) - Check interface Quantity for more details
	 */
	private calculateModuloForQuantityAndJumpAmount(quantity: number, minQuantity: number): Quantity {
		let jumpAmount = false;
		let isMinimum = false;
		let isMaximum = false;
		// Use (* 1000) for precise modulo calculation
		quantity = Math.trunc(quantity * 1000);
		minQuantity = Math.trunc(minQuantity * 1000);
		let modulo = quantity % minQuantity;
		let add = 0;
		if (quantity < minQuantity) {
			quantity = minQuantity;
			jumpAmount = true;
		} else if (modulo != 0) {
			add = minQuantity - modulo;
			jumpAmount = true;
		}

		let endValue: number;
		let maxValue = ((environment.MAX_HIGH_VALUE * 1000) - ((environment.MAX_HIGH_VALUE * 1000) % minQuantity)) / 1000;
		if ((quantity / 1000) >= maxValue) {
			endValue = maxValue;
			isMaximum = true;
		} else {
			endValue = (quantity + add) / 1000;
			if (endValue == (minQuantity / 1000)) {
				isMinimum = true;
			}
		}

		let valueString = this.transformNumberToI18nString(endValue);
		return {value: valueString, jumpAmount: jumpAmount, isMinimum: isMinimum, isMaximum: isMaximum} as Quantity;
	}

	/**
	 * Turn number value into an i18n string
	 * @param value
	 * @private
	 */
	private transformNumberToI18nString(value: number): string {
		let valueString: string | null = this._decimalPipe.transform(value, "1.0-3", this.locale);
		if (valueString == null) {
			throw new Error("Problem with transformation in the decimal pipeline");
		}
		return valueString;
	}
}
