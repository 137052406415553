<div>
	<h1 class="lato-regular" i18n="@@vbh.scanner.explanation.article.number.headline" mat-dialog-title>
		<span aria-hidden="true" class="icon-title bi bi-question-circle"></span>
		VBH Artikelnummern
	</h1>
</div>
<div mat-dialog-content>
	<table class="table table-bordered">
		<thead>
		<tr>
			<th scope="col"></th>
			<th scope="col">Beispiel</th>
			<th scope="col">Status</th>
		</tr>
		</thead>
		<tbody>
		<tr>
			<th scope="row">VBH SKU</th>
			<td>1800263507</td>
			<td>
				<span class="badge badge-success">
					<span aria-hidden="true" class="bi bi-check-lg"></span>
				</span>
			</td>
		</tr>
		<tr>
			<th scope="row">PID</th>
			<td>TEUL4331-100050</td>
			<td>
				<span class="badge badge-success">
					<span aria-hidden="true" class="bi bi-check-lg"></span>
				</span>
			</td>
		</tr>
		<tr>
			<th scope="row">EAN13</th>
			<td>4012789873405</td>
			<td>
				<span class="badge badge-success">
					<span aria-hidden="true" class="bi bi-check-lg"></span>
				</span>
			</td>
		</tr>
		</tbody>
	</table>
	<p i18n="@@vbh.scanner.explanation.description">Die hier genannten verfügbaren VBH Artikelnummern werden
		vom Scanner automatisch erkannt und können in der
		manuellen Eingabe für die Suche verwendet werden.</p>
</div>
<div mat-dialog-actions>
	<button (click)="closeInfobox()"
			class="btn primary-btn btn-lg shadow-sm"
			data-test="close-btn"
			i18n="@@vbh.scanner.explanation.dialog.close"
			matRipple>Schließen
	</button>
</div>
