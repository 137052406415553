<nav class="navbar fixed-bottom">
	<div class="row text-center">

		<button class="col-3 btn"
				matRipple
				routerLink="/scan-field"
				routerLinkActive="active"
				type="button">
			<span><img alt="scanner-icon" src="/assets/scan-icon.png"></span>
			<p class="nav-title"
			   i18n="@@vbh.scanner.navbar.scan">Scannen
			</p>
		</button>

		<button (click)="openManualInputBottomSheet()"
				class="col-3 btn"
				matRipple
				type="button">
			<span aria-hidden="true" class="bi bi-search"></span>
			<p class="nav-title"
			   i18n="@@vbh.scanner.navbar.search">Suche
			</p>
		</button>

		<button class="col-3 btn"
				matRipple
				routerLink="/product-list"
				routerLinkActive="active"
				type="button">
			<div>
				<span matBadge="{{badgeCount | async}}">
					<span aria-hidden="true" class="bi bi-card-list"></span>
				</span>
			</div>
			<p class="nav-title"
			   i18n="@@vbh.scanner.navbar.article.list">Artikelliste
			</p>
		</button>

		<button class="col-3 btn"
				matRipple
				routerLink="/settings"
				routerLinkActive="active"
				type="button">
			<span aria-hidden="true" class="bi bi-gear"></span>
			<p class="nav-title"
			   i18n="@@vbh.scanner.navbar.settings">Einstellungen
			</p>

		</button>
	</div>
</nav>

