import {Component, Inject} from "@angular/core";
import {MAT_SNACK_BAR_DATA} from "@angular/material/snack-bar";
import {SnackbarType} from "../snackbar-type";

@Component({
	selector: "app-snackbar-info",
	templateUrl: "./snackbar-info.component.html",
	styleUrls: ["./snackbar-info.component.css"]
})
export class SnackbarInfoComponent {
	attributes = {
		"disabled": true
	};

	type = SnackbarType;
	componentType: SnackbarType;

	constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) {
		this.componentType = this.data as SnackbarType;
	}

}
