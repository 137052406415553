<form [formGroup]="formValidator" class="container">
	<div class="row custom-row">
		<div class="col-4">
			<img [src]="this.imageUrl"
				 alt="Product image">
		</div>
		<div class="col-8 product-info">
			<h4 data-test="name">{{productListBo.name}}</h4>
			<p class="lato-light" data-test="sku">{{productListBo.sku}}</p>
			<div *ngIf="productListBo.variation !== undefined">
				<div *ngFor="let variation of productListBo.variation">
					<p class="subtitle-variations">{{variation.name}}: {{variation.value}}</p>
				</div>
			</div>
		</div>
	</div>
	<div class="edit-container mb">
		<div class="form-group">
			<label i18n="@@vbh.scanner.bs.edit.article.number">Artikelnummer</label>
			<input class="form-control quantity-input"
				   data-test="sku-input"
				   formControlName="skuInput"
				   id="skuInput"
				   type="text"
			>
			<div *ngIf="form.skuInput.touched && form.skuInput.invalid">
				<div *ngIf="form.skuInput.errors?.required"
					 class="alert alert-info"
					 i18n="@@vbh.scanner.bs.edit.validation.insert.sku">Geben Sie eine gültige Artikelnummer ein.
				</div>
				<div *ngIf="form.skuInput.errors?.pattern"
					 class="alert alert-danger"
					 i18n="@@vbh.scanner.bs.edit.validation.sku">Eine Artikelnummer besteht aus mindestens 10 Zahlen.
				</div>
			</div>
		</div>
		<button (click)="update()"
				[disabled]="form.skuInput.invalid || formSkuValue === this.productListBo.sku"
				class="btn primary-btn btn-lg shadow-sm"
				i18n="@@vbh.scanner.bs.edit.update"
				matRipple>Aktualisieren
		</button>
	</div>
	<div class="edit-container mb">
		<div class="form-group">
			<label i18n="@@vbh.scanner.bs.edit.article.quantity">Artikelmenge</label>
			<app-quantity [minQuantity]="productListBo.minQuantity"
						  [parentForm]="formValidator"
						  [startValue]="productListBo.quantity">
			</app-quantity>
		</div>
	</div>
	<!-- Variation dropdown -->
	<div *ngFor="let dropdown of dropdownList">
		<div class="edit-container mb">
			<mat-form-field appearance="outline">
				<mat-label>{{dropdown.type}}</mat-label>
				<mat-select (selectionChange)="dropdownSelectionChange(dropdown.type, $event.value)"
							[(value)]="dropdown.selectedValue.value">
					<mat-option
						*ngFor="let data of dropdown.data"
						[disabled]="this.status===0" [value]="data.value">{{data.value}}
						<ng-container *ngIf="data.differentCombination"> - in anderer Kombination verfügbar
						</ng-container>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
	</div>
</form>
<div class="modal-footer">
	<button
		(click)="openDialog()"
		class="delete-btn btn btn-lg shadow-sm col-6"
		data-test="delete-dialog"
		i18n="@@vbh.scanner.bs.edit.delete"
		matRipple>Löschen
	</button>
	<button
		(click)="editProduct()"
		[disabled]="(formValidator.invalid) || (form.skuInput.invalid)
		|| ((formQuantityValue === this.productListBo.quantity)
		&& ((formSkuValue !== this.productListBo.sku)
		|| (this.productListBo.sku === this.oldSku)))"
		class="btn primary-btn btn-lg shadow-sm col-6"
		data-test="save-btn"
		i18n="@@vbh.scanner.bs.edit.save"
		matRipple
		type="submit">Speichern
	</button>
</div>
