import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {SettingsComponent} from "./components/settings/settings.component";
import {ScanFieldComponent} from "./components/scan-field/scan-field.component";
import {ProductScanListComponent} from "./components/product-scan-list/product-scan-list.component";
import {
	BottomSheetProductInfoComponent
} from "./components/bottom-sheets/bottom-sheet-product-info/bottom-sheet-product-info.component";

const routes: Routes = [
	{path: "settings", component: SettingsComponent},
	{path: "scan-field", component: ScanFieldComponent},
	{path: "product-list", component: ProductScanListComponent},
	{path: "product-list/:sku/:quantity", component: ProductScanListComponent},
	{path: "product-info/:id", component: BottomSheetProductInfoComponent},
	{path: "", redirectTo: "/scan-field", pathMatch: "full"},

];

@NgModule({
	imports: [RouterModule.forRoot(routes,{ useHash: true })],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
