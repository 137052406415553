import {createAction, props} from "@ngrx/store";
import {ProductListBO} from "../components/product-scan-list/product-list-bo.model";
import {HeaderType} from "../shell/shared/header/header-type";

export const addProduct = createAction(
	"[Product Info] Add Product",
	props<{ product: ProductListBO }>()
);

export const editProduct = createAction(
	"[Product List] Edit Product",
	props<{ product: ProductListBO, oldSku: string }>()
);

export const initialProductList = createAction(
	"[Product List] Initial Product List",
	props<{ initialProductList: ProductListBO[] }>()
);

export const deleteProduct = createAction(
	"[Product List] Delete Product",
	props<{ sku: string }>()
);

export const checkProduct = createAction(
	"[Product List] Check Products",
	props<{ list: ProductListBO[] }>()
);

export const startSpinner = createAction(
	"[Spinner] Start Spinner"
);

export const stopSpinner = createAction(
	"[Spinner] Stop Spinner"
);

export const headerTyp = createAction(
	"[headerType] Scan",
	props<{ headerType: HeaderType }>()
);


