<div class="container">
	<div class="modal-header modal-header--sticky">
		<h2 *ngIf="componentType === type.MANUAL_INPUT"
			class="modal-title lato-bold" i18n="@@vbh.scanner.bs.title.search">Suche
		</h2>
		<h2 *ngIf="componentType === type.PRODUCT_INFO"
			class="modal-title lato-bold" i18n="@@vbh.scanner.bs.title.article">Gefundener Artikel
		</h2>
		<h2 *ngIf="componentType === type.CONFIRMATION_FAIL"
			class="modal-title lato-bold" i18n="@@vbh.scanner.bs.title.not-found">Artikel nicht gefunden
		</h2>
		<h2 *ngIf="componentType === type.PRODUCT_EDIT"
			class="modal-title lato-bold" i18n="@@vbh.scanner.bs.title.edit-article">Artikel bearbeiten
		</h2>
		<button (click)="closeBottomSheet()"
				aria-label="Close"
				class="close"
				data-dismiss="modal" type="button">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<ng-template appBottomSheet></ng-template>
	</div>
</div>

