<div *ngIf="emptyList | async; else fullList"
	 class="custom-container">
	<img alt="Empty list" src="assets/empty-list.png">
	<h4 class="lato-bold">Die Artikelliste ist leer</h4>
	<p class="lato-light">Über den Scan oder die Suche können Artikel hinzugefügt werden.</p>
</div>

<ng-template #fullList>
	<div class="container" style="width: 100%">
		<table class="table">
			<tbody>
			<tr *ngFor="let productListBo of productList | async">
				<th>
					<span *ngIf="productListBo.existStatus === allExistStatus.EXIST_GREEN"
						  class="badge badge-success">
						<span aria-hidden="true" class="bi bi-check-lg"></span>
					</span>
					<span *ngIf="productListBo.existStatus === allExistStatus.NOT_EXIST_RED"
						  class="badge badge-danger">
						<span aria-hidden="true" class="bi bi-x-lg"></span>
					</span>
					<span *ngIf="productListBo.existStatus === allExistStatus.UNKNOWN_GREY"
						  class="badge badge-secondary">
						<span aria-hidden="true" class="bi bi-question-lg"></span>
					</span>
					<h4 (click)="openEditBottomSheet(productListBo.sku)">
						{{productListBo.name | slice:0:18}}...
					</h4>
					<p class="subtitle lato-light">{{productListBo.sku}}</p>
					<div *ngIf="productListBo.variation !== undefined">
						<div *ngFor="let variation of productListBo.variation">
							<p class="subtitle-variations">{{variation.name}}: {{variation.value}}</p>
						</div>
					</div>
					<p (click)="openJumpAmountInfo()"
					   *ngIf="productListBo.checkJumpAmount !== undefined && productListBo.checkJumpAmount.hasJumpAmount
						&& productListBo.checkJumpAmount.oldQuantity !== undefined"
					   class="customized-quantity"
					   data-test="jump amount"
					   i18n="@@vbh.scanner.product.scan.list.customized.quantity">
						<i class="bi bi-exclamation-circle"></i> Mengenanpassung
						{{productListBo.checkJumpAmount.oldQuantity}}
						<i class="bi bi-arrow-right"></i>
						{{productListBo.quantity}}
					</p>
				</th>
				<td>
					<div *ngIf="productListBo.checkJumpAmount !== undefined && productListBo.checkJumpAmount.hasJumpAmount
						&& productListBo.checkJumpAmount.oldQuantity !== undefined; else quantityTemplate">
						<h4 class="subtitle-quantity">
							{{productListBo.quantity}}
						</h4>
					</div>
					<ng-template #quantityTemplate>
						<h4 class="subtitle-quantity"> {{productListBo.quantity}}</h4>
					</ng-template>
				</td>
				<td>
					<button (click)="openEditBottomSheet(productListBo.sku)"
							aria-hidden="true"
							class="btn edit-btn bi bi-pencil">
					</button>
				</td>
				<td>
					<button
						(click)="openDialog(productListBo.sku)"
						aria-hidden="true"
						class="btn edit-btn bi bi-trash">
					</button>
				</td>
			</tr>
			</tbody>
		</table>
		<div
			class="modal-footer modal-footer--sticky">
			<button (click)="checkProducts()"
					[disabled]="(this.status && (unknownAvailable | async)) || !this.status"
					class="btn secondary-btn btn-lg shadow-sm"
					data-test="check"
					i18n="@@vbh.scanner.product.scan.list.check.scanned.items"
					matRipple>Artikel prüfen
			</button>
			<button (click)="addToBasket()"
					[disabled]="!this.status"
					class="btn primary-btn btn-lg shadow-sm"
					data-test="check"
					i18n="@@vbh.scanner.product.scan.list.to.cart"
					matRipple>Zum Warenkorb
			</button>
		</div>
	</div>
</ng-template>
