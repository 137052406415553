<scandit-sdk-barcode-picker
	id="barcode-picker"
	(scan)="onScan($any($event))"
	[cameraSettings]="cameraSettings"
	[scanSettings]="activeSettings"
	[videoFit]="videoFit"
	[style.height]="height || '94%'"
	[style.width]="width || '100vw'"
>
</scandit-sdk-barcode-picker>
