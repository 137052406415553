import {AfterViewInit, Component, Input, OnInit} from "@angular/core";
import {ProductBO} from "../../../services/model/product/product-bo";
import {BottomSheetComponent} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-component";
import {BottomSheetMainComponent} from "../../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {ManualInputData} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/data/manual-input-data";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {ProductListService} from "../../../services/product/product-list.service";
import {ImageCarouselService} from "../../../services/image/image-carousel.service";
import {ProductListBO} from "../../product-scan-list/product-list-bo.model";
import {ExistStatus} from "../../../services/product/exist-status";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {selectProductList} from "../../../state/product-list.selectors";
import {startSpinner, stopSpinner} from "../../../state/product-list.actions";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";
import {SnackbarType} from "../../snackbar/snackbar-type";

/**
 * Component for showing the product information.
 * You can change the quantity on the product and add it to your list.
 */
@Component({
	selector: "app-bottom-sheet-product-info",
	templateUrl: "./bottom-sheet-product-info.component.html",
	styleUrls: ["./bottom-sheet-product-info.component.css"]
})
export class BottomSheetProductInfoComponent implements OnInit, BottomSheetComponent, AfterViewInit {
	public productBo: ProductBO = {
		name: "",
		barcode: "",
		imageUrls: [],
		sku: "",
		minOrderQuantity: 0,
	};
	public productList: ProductListBO[] = [];
	public isLoadingProductBos: boolean = true;
	private _data: ManualInputData = {productBO: this.productBo};
	
	@Input()
	set data(value: ManualInputData) {
		this._data = value;
		this.productBo = this._data.productBO;
		this.productBos = this._data.productRecommendations;
		this.imageUrl = this.imageService.checkImage(this.productBo.imageUrls, this.imageIndex);
	} 

	get data(): ManualInputData {
		return this._data;
	}
	// Image
	@Input() imageUrl: string = "";
	public quantityForm: UntypedFormGroup = new UntypedFormGroup({
		quantityFormInput: new UntypedFormControl("", Validators.compose(
			[Validators.minLength(1),
				Validators.maxLength(13),
				Validators.pattern("^[^a-zA-Z]+$"),
				Validators.required])),
	});
	private imageIndex = 0;


	public productBos: ProductBO[];
	public productBosChecked: ProductBO[];

	constructor(
		public bottomSheetMainComponent: BottomSheetMainComponent,
		private router: Router,
		private productListService: ProductListService,
		public imageService: ImageCarouselService,
		private store: Store<AppState>,
		private snackbarService: SnackbarService,
	) {
	}

	public ngOnInit(): void {
		this.productBo = this.data.productBO;
		this.productBos = this.data.productRecommendations;
		this.productBosChecked = [];
		this.imageUrl = this.imageService.checkImage(this.productBo.imageUrls, this.imageIndex);
		this.store.select(selectProductList).subscribe((list: ProductListBO[]): any => {
			this.productList = list;
		});
	}

	public ngAfterViewInit(): void {
		if (navigator.onLine) {
			this.store.dispatch(stopSpinner());
		}
		this.store.dispatch(stopSpinner());
	}

	/**
	 * Rotate the images like a carousel.
	 */
	public changeImage(): void {
		let imageValue = this.imageService.changeImage(this.productBo.imageUrls, this.imageIndex);
		this.imageUrl = imageValue[0];
		this.imageIndex = imageValue[1];
	}

	/**
	 * Go back to rescan another product.
	 */
	public rescan(): void {
		this.bottomSheetMainComponent.closeBottomSheet();
	}

	/**
	 * Add product to the list. If offline exist status is unknown.
	 */
	public addProductToList(): void {
		let productListBo: ProductListBO = {
			imageUrls: this.productBo.imageUrls,
			barcode: this.productBo.barcode,
			minQuantity: this.productBo.minOrderQuantity,
			name: this.productBo.name,
			sku: this.productBo.sku,
			quantity: this.quantityForm.value.quantityFormInput,
			existStatus: ExistStatus.UNKNOWN_GREY,
			variation: this.productBo.variation,
			productMasterSKU: this.productBo.productMasterSKU
		};
		if (navigator.onLine) {
			productListBo.existStatus = ExistStatus.EXIST_GREEN;
		}
		this.store.dispatch(startSpinner());
		let isMaximum = this.productListService.addProduct(productListBo, this.productList);
		if (isMaximum) {
			this.snackbarService.openSnackbar(SnackbarType.ADD_TO_LIST_MAXIMUM);
		} else {
			this.snackbarService.openSnackbar(SnackbarType.ADD_TO_LIST);
		}
		this.addCheckedProductsToList()
		this.bottomSheetMainComponent.closeBottomSheet();
	}

	public addToCheckedBos(bo: ProductBO, event: Event){
		let isChecked = (event.target as HTMLInputElement).checked
		if(isChecked){
			this.productBosChecked.push(bo)
		} else {
			this.productBosChecked = [...this.productBosChecked.filter(checkedBos => checkedBos != bo)]	
		}
	}

	public isChecked(bo: ProductBO): ProductBO {
		return this.productBosChecked.find(item => item === bo)
	}

	private addCheckedProductsToList(): void {
		if (this.productBosChecked.length > 0){
			for(const checkedBo of this.productBosChecked) {
				let productListBo: ProductListBO = {
					imageUrls: checkedBo.imageUrls,
					barcode: checkedBo.barcode,
					minQuantity: checkedBo.minOrderQuantity,
					name: checkedBo.name,
					sku: checkedBo.sku,
					quantity: JSON.stringify(checkedBo.minOrderQuantity),
					existStatus: ExistStatus.UNKNOWN_GREY,
					variation: checkedBo.variation,
					productMasterSKU: checkedBo.productMasterSKU
				};
				if (navigator.onLine) {
					productListBo.existStatus = ExistStatus.EXIST_GREEN;
				}
				let isMaximum = this.productListService.addProduct(productListBo, this.productList);
				if (isMaximum) {
					this.snackbarService.openSnackbar(SnackbarType.ADD_TO_LIST_MAXIMUM);
				} else {
					this.snackbarService.openSnackbar(SnackbarType.ADD_TO_LIST);
				}
			}
		}
	}


}
