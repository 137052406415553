import {ChangeDetectorRef, Component, ComponentRef, Type, ViewChild} from "@angular/core";
import {BottomSheetDirective} from "../bottom-sheet-dynamic-component/bottom-sheet.directive";
import {BottomSheetComponent} from "../bottom-sheet-dynamic-component/bottom-sheet-component";
import {
	BottomSheetProductInfoComponent
} from "../../bottom-sheets/bottom-sheet-product-info/bottom-sheet-product-info.component";
import {BottomSheetData} from "../bottom-sheet-dynamic-component/data/bottom-sheet-data";
import {BottomSheetType} from "../bottom-sheet-dynamic-component/bottom-sheet-type";
import {
	BottomSheetManualInputComponent
} from "../../bottom-sheets/bottom-sheet-manual-input/bottom-sheet-manual-input.component";
import {
	BottomSheetConfirmationFailComponent
} from "../../bottom-sheets/bottom-sheet-confirmation-fail/bottom-sheet-confirmation-fail.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {
	BottomSheetEditScanListComponent
} from "../../bottom-sheets/bottom-sheet-edit-scan-list/bottom-sheet-edit-scan-list.component";

@Component({
	selector: "app-bottom-sheet-main",
	templateUrl: "./bottom-sheet-main.component.html",
	styleUrls: ["./bottom-sheet-main.component.css"]
})
export class BottomSheetMainComponent {
	// Placeholder for the different bottom sheets
	@ViewChild(BottomSheetDirective, {static: true})
		appBottomSheet!: BottomSheetDirective;
	public type = BottomSheetType;
	public componentType: BottomSheetType = BottomSheetType.DEFAULT;
	private mainFieldComponents: { type: BottomSheetType, comp: Type<BottomSheetComponent> }[] = [
		{type: BottomSheetType.MANUAL_INPUT, comp: BottomSheetManualInputComponent},
		{type: BottomSheetType.PRODUCT_INFO, comp: BottomSheetProductInfoComponent},
		{type: BottomSheetType.CONFIRMATION_FAIL, comp: BottomSheetConfirmationFailComponent},
		{type: BottomSheetType.PRODUCT_EDIT, comp: BottomSheetEditScanListComponent}
	];
	private componentRef: ComponentRef<BottomSheetComponent>;

	constructor(
		public _bottomSheet: MatBottomSheet,
		private cd: ChangeDetectorRef
	) {
	}

	public closeBottomSheet(): void {
		this._bottomSheet.dismiss();
	}

	/**
	 * Loads the different bottom sheet components into the directive view child
	 * @param typeBot The typeBot defines which bottom sheet component should be load.
	 * @param data Specific data of the that the bottom sheet component needs. For example the product to show in the product info.
	 */
	public loadComponent(typeBot: BottomSheetType, data?: BottomSheetData): void {
		const viewContainerRef = this.appBottomSheet.viewContainerRef;
		viewContainerRef.clear();
		let result = this.mainFieldComponents.find((component): any => component.type === typeBot as BottomSheetType);
		if (result == undefined) {
			return;
		}
		this.componentRef = viewContainerRef.createComponent<BottomSheetComponent>(result.comp);
		this.componentType = result.type;
		if (data == undefined) {
			return;
		}
		this.componentRef.instance.data = data;
		// Fix the Error : `ExpressionChangedAfterItHasBeenCheckedError` --> https://angular.io/errors/NG0100
		this.cd.detectChanges();

	}

	public updateData(data: BottomSheetData): void {
        if (this.componentRef && data) {
            this.componentRef.instance.data = data;
            this.cd.detectChanges();
        }
    }
}
