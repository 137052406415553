import {AfterViewInit, Component, OnInit} from "@angular/core";
import {ProductListBO} from "./product-list-bo.model";
import {ProductListService} from "../../services/product/product-list.service";
import {MatBottomSheet, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ExistStatus} from "../../services/product/exist-status";
import {OnlineStatusService, OnlineStatusType} from "ngx-online-status";
import {BasketService} from "../../services/basket/basket.service";
import {MatDialog} from "@angular/material/dialog";
import {
	DeleteConfirmationDialogComponent
} from "../bottom-sheets/bottom-sheet-edit-scan-list/delete-confirmation-dialog/delete-confirmation-dialog.component";
import {Observable, of} from "rxjs";
import {ProductEditData} from "../bottom-sheet-core/bottom-sheet-dynamic-component/data/product-edit-data";
import {BottomSheetMainComponent} from "../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {BottomSheetType} from "../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-type";
import {Store} from "@ngrx/store";
import {AppState} from "../../state/state";
import {
	selectAllProductExist,
	selectFindProduct,
	selectProductList,
	selectProductListIsEmpty
} from "../../state/product-list.selectors";
import {headerTyp, startSpinner, stopSpinner} from "../../state/product-list.actions";
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {SnackbarType} from "../snackbar/snackbar-type";
import {HeaderType} from "../../shell/shared/header/header-type";
import { CheckoutDialogComponent } from "./checkout-dialog/checkout-dialog.component";

@Component({
	selector: "app-product-scan-list",
	templateUrl: "./product-scan-list.component.html",
	styleUrls: ["./product-scan-list.component.css"]
})
export class ProductScanListComponent implements OnInit, AfterViewInit {
	productBo: ProductListBO = {
		name: "",
		barcode: "",
		imageUrls: [],
		sku: "",
		quantity: "0",
		minQuantity: 2,
		existStatus: ExistStatus.EXIST_GREEN
	};

	public productList: Observable<ProductListBO[]> = of([]);
	public productListStatic: ProductListBO[] = [];
	public allExistStatus = ExistStatus;
	public unknownAvailable: Observable<boolean> = of(false);
	status: OnlineStatusType = this.onlineStatusService.getStatus();
	public emptyList: Observable<boolean> = of(false);

	constructor(
		private _bottomSheet: MatBottomSheet,
		public dialog: MatDialog,
		private onlineStatusService: OnlineStatusService,
		private basketService: BasketService,
		private productListService: ProductListService,
		private store: Store<AppState>,
		private snackbarService: SnackbarService
	) {
		this.onlineStatusService.status.subscribe((status: OnlineStatusType): any => {
			// use status
			this.status = status;
		});
		this.store.dispatch(headerTyp({headerType: HeaderType.ARTICLE_LIST}));
	}

	ngOnInit(): void {
		this.emptyList = this.store.select(selectProductListIsEmpty);
		this.productList = this.store.select(selectProductList);
		this.productList.subscribe((list: ProductListBO[]): any => this.productListStatic = list);
		this.unknownAvailable = this.store.select(selectAllProductExist);
	}

	public ngAfterViewInit(): void {
		if (navigator.onLine) {
			this.store.dispatch(stopSpinner());
		}
		this.store.dispatch(stopSpinner());
	}

	/**
	 * Opens the bottom sheet to edit the product
	 * @param sku
	 */
	public openEditBottomSheet(sku: string): void {
		this.store.select(selectFindProduct(sku)).subscribe((product: ProductListBO):
		any => {
			if (product === undefined) {
				return;
			}
			let editData: ProductEditData = {productListBo: product};
			const bottomSheetRef: MatBottomSheetRef<BottomSheetMainComponent> = this._bottomSheet.open(BottomSheetMainComponent);
			bottomSheetRef.instance.loadComponent(BottomSheetType.PRODUCT_EDIT, editData);
		}).unsubscribe();
	}

	public checkProducts(): void {
		this.store.dispatch(startSpinner());
		this.productListService.checkProductsExist();
	}

	public addToBasket(): void {
		this.dialog.open(CheckoutDialogComponent);
	}

	public openDialog(sku: string): void {
		this.dialog.open(DeleteConfirmationDialogComponent, {
			data: {
				sku: sku,
			}
		});
	}

	public openJumpAmountInfo(): void {
		this.snackbarService.openSnackbar(SnackbarType.JUMP_AMOUNT_INFO);
	}
}
