import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from "@angular/core";
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "./app-routing.module";
import {AppComponent} from "./app.component";
import {ServiceWorkerModule} from "@angular/service-worker";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HttpClientJsonpModule, HttpClientModule} from "@angular/common/http";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DecimalPipe, HashLocationStrategy, LocationStrategy, registerLocaleData} from "@angular/common";
import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import {OnlineStatusModule} from "ngx-online-status";

// Material Components
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatBadgeModule} from "@angular/material/badge";
import {MatRippleModule} from "@angular/material/core";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSelectModule} from "@angular/material/select";

import {HeaderComponent} from "./shell/shared/header/header.component";
import {BottomNavbarComponent} from "./shell/shared/bottom-navbar/bottom-navbar.component";
import {SettingsComponent} from "./components/settings/settings.component";
import {ScanFieldComponent} from "./components/scan-field/scan-field.component";
import {ProductScanListComponent} from "./components/product-scan-list/product-scan-list.component";
import {BottomSheetMainComponent} from "./components/bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {
	BottomSheetManualInputComponent
} from "./components/bottom-sheets/bottom-sheet-manual-input/bottom-sheet-manual-input.component";
import {
	BottomSheetProductInfoComponent
} from "./components/bottom-sheets/bottom-sheet-product-info/bottom-sheet-product-info.component";
import {ProductErrorHandler} from "./services/error-handling/product-error-handler";
import {
	BottomSheetDirective
} from "./components/bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet.directive";
import {
	BottomSheetConfirmationFailComponent
} from "./components/bottom-sheets/bottom-sheet-confirmation-fail/bottom-sheet-confirmation-fail.component";
import {
	CodeExplanationDialogComponent
} from "./components/bottom-sheets/bottom-sheet-manual-input/code-explanation-dialog/code-explanation-dialog.component";
import {
	BottomSheetEditScanListComponent
} from "./components/bottom-sheets/bottom-sheet-edit-scan-list/bottom-sheet-edit-scan-list.component";
import {
	DeleteConfirmationDialogComponent
} from "./components/bottom-sheets/bottom-sheet-edit-scan-list/delete-confirmation-dialog/delete-confirmation-dialog.component";
import {
	DefaultQuantityDialogComponent
} from "./components/settings/default-quantity-dialog/default-quantity-dialog.component";
import {QuantityComponent} from "./components/quantity-input/quantity.component";
import {SnackbarInfoComponent} from "./components/snackbar/snackbar-info/snackbar-info.component";
import {StoreModule} from "@ngrx/store";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {environment} from "../environments/environment";
import {LoadingSpinnerComponent} from "./components/loading-spinner/loading-spinner.component";
import {headerReducer, loadingReducer, productReducer, spinnerReducer} from "./state/product-list.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ProductListEffects} from "./state/product-list.effects";

registerLocaleData(localeDe, "de-DE", localeDeExtra);
import {ScanditSdkModule} from "scandit-sdk-angular";
import {CheckoutDialogComponent} from "./components/product-scan-list/checkout-dialog/checkout-dialog.component";

//const licenseKeyIntUat: string = "AdUi4xhlPcDNJN3lkD2kzyI/Atc9FQwgRAz2Q9Re4qX9fppXjjSJfqcjq61rWr0IXnD2k8tICY5HChuyaAfh47sBm4qFSW6OcQtGBtwddtz/dC9clQC9wORvCyfjAHj/Ik9IcwtDe4snLy/b9UOOWhgaZ8vBYs1jRFp2+opgdP5xZivPakTocI5yh3l7Y4ahmGMZJ51Qoe7UFYq+Z0DK68cnt/DJQPmDHUHtlT8kT21a2f8yfBg7y/aWVobCMwqJUh/QWzMnT7T5V+JItkKvhV/fHl97ASo2TpVLu4L12fY6PSo8AnrqjJA8IO9wODZBQ3UaZpO0Bc70JQ4bhi7ksEXhlkN2EvLN49jIIwtQhw/4ye5TiLKwGN6gSRcf9cGyZAJgcOq6w1v4Q/JvNdIMmV6qEOXmEsx9i6wcKiU3H8YZ6KdNsdkuZr7NhtVJWC6AWwjs92IDKMvyvLe27WVwgVuJ+273d6eVdQJe8D6qqsZiFmNM204qlbnKQNH/cRXJ0P8T/77aOm1vpj53+nhwrIyuT9ZQzVlJ2V4owP62vABmLjMefas+VSLrrprb8EeZ57IO3kXiw6SBtaEXJ0t61DCfwK1BrzYzw8ek6eIsTsxdkirPcMSjlIH8p/3q1T9/MKCkfMdxM002/bt0/bzT4ouIwfeNH2m/Z3LJxN91tiCgqBIE/3HjZIrG+ZdFNKpJYqj8X9wmkQz2WsjI1ixY1MePyQ31OF9UuF9rF27NDrBR3YlsIb11ReQlQN57rqzlvku1lywmgfVUKZTanAPtnnZ7XxiY06baOJJkMy2cowAUdO1WONUST25+1s1n/dV9s2gYU8FilaCTf6+sGaxtgCxy2W2IhtZD5t/OSC/M4CThCl3ytmYtjSKDy/VqME8ZLV29eBGXoUxHtVB+PrxJ";
//const licenseKeyTestXIM: string = "Ab2i1xM8NqiBQWGWbyW75k0pHcn1GFQKdQAnPS0gS9rqZP2LG1yMKcZtOocqfzXAi0+2pUJudlx9GmasQXPsddgnCBcUZy6kR3VJx3JWWdP/AxuRJQFMuEsw5JumWT4hnkbVHMd16XWSblCSIH3sEWo9zdd8YfQzN2+9Ytk4rFHOAY6PbhbOxm4nzZuaNreIeEfogcu3uMfpPOf6yj59MMVj9B+HdrQrGwy/Scs8CK9dwGdbMhMYQZM9Yty8D8ykfPjU212D8qQPVPt2Y3xhpQrc2HRdZO+kQ8exCuylP+ZS3TK4iOcV10PrLAKNOD6HUOWEZlsOmYEF85Za8TUAJm1jkAIeiBT5xUEB4GhakqUlRfx+RBhsu0mtmwD6ukmej8jf5bG7DIjOPatT//ZJKV2rYb+19xyxrLnOvFAmv3f3+VN6fFb14BaPzAII7MHCq7jikVwYRW2nceyuiGhr0XwNvoBjA+mbl0SYv46tjA0f8j35pk0xF5f4aceDqw5q0uQOCTBgxtYLWsj+Qgb1MizNc4ej3u7DNaKAXI3XKXEt3uDtvtERP3QgZ8G1GYkMcPe6TcaDyTMYRL90WWV1aqgusLhWMQPLC0eYRPmpC1FGUu3nScxd7AtcWXvU8XauVbJeT9nPrCyaL77Lf/aXpWs8xMVQfotsWY5GNSEUk7eZgs4iv4DqJB9lyrM2dk/n8l+zgf6zIljOwxncTzbhjuYaszYVQCkQ4twJMQ0UadhfgkzwmDUr1NLRgu3ngdYbh2fiwqbc5/K7ldo9HgO6i2/ass0ksr2Rfr6tmERa79FNLU+G1RvKBm5PwNKHJJszf788cJE93mqLoi2afDbSgX6JykTI78VyhBW40fx2";
//const licenseKey: string = "AfBRAGDzROMJQknfpzVzvVM9o+b+dH8YEQhTS8BcZviUYDPL10TgpZUfia8abUz1w3bAI5ZRXHPhR64jYXIoyjhH3SzvXUXedzgpjGNX64PpW5LLWyHlVSpjAE0UNdGOTwuf0jkn6XLaOanYjx8DIJGKZMk+3YabYBeSZ0MaQ5a4SSatcU1HVI3kV4OVFNGI1IRk2oxcTB3aIAw4wZugJf9hB0UIvC3oDdK66lQzejrbSE9BMNCjSBf2Rpy2BUmI2vmIpYPBiB67lHEOPfBBc66lfj8Ft2TcJ39bfd9vMLRP+62vTTBiDWEn8qKEiYVuR2pQ/XwCv6RzpNAWoyjyozqjiOAsqdQ7NTfwX54TCuHUM9VTzIqkyX8KX5C7Fpx8MB2moWogN5heKXr/ufWE+yLTshmKOT0RB9zbH+5O9DduNCVnGIzWaVvFiau2a1ILPObr0p8oj9naLwjtJq23DAIxtYa1KA1mhMJ5eAehbK3q5lRXd4qW2gBUpvFPqiZNnoJNrScHCXQeXnKVTG7GXlGmllMTk70KnV8QcIrnbIKsoXxj/9pD4qqY8inp5uBcSpHb3Jg83xalhmqHxu9XHGh0PjqTe90FdLekmIxx2DvTKF2iW4TfA9GF0zzcFnVUqN6rGnr63thfiqghR3LtmeoPsRMtok1EnUoEB8M6t8KCCOrLUfiIjDfJw9Mo7eu2HQmg/vQ7VTX9svvu7pdC4Qivpf4hk38ihSJR2lTfNFhZAWVL1DtjvEcjWItP988AyOJv2D95n0uYUmjQInD/qomYuF/rSClecLMXiDF9ddZ4vDn139n5b8xhryNn5A==";
const engineLocation: string = "https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/";

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		BottomNavbarComponent,
		SettingsComponent,
		ScanFieldComponent,
		ProductScanListComponent,
		BottomSheetMainComponent,
		BottomSheetManualInputComponent,
		BottomSheetProductInfoComponent,
		BottomSheetDirective,
		BottomSheetConfirmationFailComponent,
		CodeExplanationDialogComponent,
		BottomSheetEditScanListComponent,
		DeleteConfirmationDialogComponent,
		BottomSheetEditScanListComponent,
		DefaultQuantityDialogComponent,
		QuantityComponent,
		SnackbarInfoComponent,
		LoadingSpinnerComponent,
		CheckoutDialogComponent],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientJsonpModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: environment.production,
			// Register the ServiceWorker as soon as the app is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000",
		}),
		MatBottomSheetModule,
		MatIconModule,
		MatDialogModule,
		MatSnackBarModule,
		MatBadgeModule,
		MatRippleModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		HttpClientModule,
		OnlineStatusModule,
		StoreModule.forRoot({
			loading: loadingReducer,
			productList: productReducer,
			spinner: spinnerReducer,
			headerType: headerReducer
		}),
		EffectsModule.forRoot([ProductListEffects]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production, // Restrict extension to log-only mode
			autoPause: true, // Pauses recording actions and state changes when the extension window is not open
		}),
		ScanditSdkModule.forRoot(environment.scanditLicenseKey, {engineLocation})
	],
	providers: [DecimalPipe,
		{provide: ErrorHandler, useClass: ProductErrorHandler},
		{provide: LOCALE_ID, useValue: "de-DE"},
		{provide: LocationStrategy, useClass: HashLocationStrategy}
	],
	bootstrap: [AppComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
