import {Injectable} from "@angular/core";
import {
	BottomSheetMainComponent
} from "../../components/bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {Store} from "@ngrx/store";
import {AppState} from "../../state/state";
import {BottomSheetType} from "../../components/bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-type";
import {startSpinner, stopSpinner} from "../../state/product-list.actions";
import {ProductBO} from "../../services/model/product/product-bo";
import {
	ManualInputData
} from "../../components/bottom-sheet-core/bottom-sheet-dynamic-component/data/manual-input-data";
import {ProductService} from "../../services/product/product.service";
import { concatMap, map } from "rxjs";
import {AppSettingsService} from "../../components/settings/app-settings.service";
import { MiddlewareService } from "src/app/services/middleware/middleware.service";

@Injectable({
	providedIn: "root"
})
export class ProductFacade {

	constructor(private productService: ProductService,
		private store: Store<AppState>,
		private middlewareService: MiddlewareService,
		private settingsService: AppSettingsService,) {
	}

	private loadImage(url: string): Promise<void> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onload = () => resolve();
			img.onerror = reject;
			img.src = url;
		});
	}


	/**
     * Navigate the component when a specific code is planning. Method is used for manual and scan input.
     * @param barcode The specific code that is from the manual input or the scan. EAN13, SKU,...
     * @param bottomSheetMainComponent Bottomsheet of the component
     */

	public setProduct(barcode: string, bottomSheetMainComponent: BottomSheetMainComponent): void {
		barcode = barcode.replace(/ /g, "");
		if (navigator.onLine) {
			let manualInputData: ManualInputData = {productBO: undefined, productRecommendations: undefined};
			this.productService.getProductBO(barcode).pipe(
				map((productBO: ProductBO) => {
					manualInputData.productBO = productBO;
					return {"defaultCategory" :productBO.defaultCategory, "sku" :productBO.sku};
				}),
			).subscribe(
				{
					next: (information: any) => {

						this.loadImage(manualInputData.productBO.imageUrls[0]).then(() => {
							bottomSheetMainComponent.loadComponent(BottomSheetType.PRODUCT_INFO, manualInputData);
							this.middlewareService.sendMiddlewareRequest(information).pipe(
								map((list: []) => {
									manualInputData.productRecommendations = list;
									return manualInputData;
								})
						).subscribe(
							{
                                next: (manualInputData: ManualInputData) => {
                                    // Update the bottomSheetMainComponent with the data from middlewareService
									
                                    bottomSheetMainComponent.updateData(manualInputData);

                                },
                                error: (error) => {
                                    this.store.dispatch(stopSpinner());
                                }
                            }
                        );
                    }).catch((error) => {
                        console.error('Error loading image', error);
                    });
                },
					error: (error) => {
						bottomSheetMainComponent.loadComponent(BottomSheetType.CONFIRMATION_FAIL);
						this.store.dispatch(stopSpinner());
					}
				}
			);

		} else {
			let offlineProduct = {
				imageUrls: [],
				minOrderQuantity: 1,
				name: "",
				sku: barcode,
				barcode: barcode
			};
			let manualInputData: ManualInputData = {productBO: offlineProduct};
			bottomSheetMainComponent.loadComponent(BottomSheetType.PRODUCT_INFO, manualInputData);
			this.store.dispatch(stopSpinner());
		}
	}
}