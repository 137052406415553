<div class="spinner-overlay-container">
	<div class="spinner-container">
		<mat-progress-spinner
			mode="indeterminate"
			value="50"
			diameter="200"
			strokeWidth="2"
		>
		</mat-progress-spinner>
		<img src="assets/logo.svg" alt="bild">
	</div>
</div>
