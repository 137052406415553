import {Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {BasketService} from "../../../services/basket/basket.service";
import {ProductListBO} from "../product-list-bo.model";
import {startSpinner, stopSpinner} from "../../../state/product-list.actions";
import {ExistStatus} from "../../../services/product/exist-status";
import {Observable, of} from "rxjs";
import {
	selectAllProductExist,
	selectProductList,
} from "../../../state/product-list.selectors";
import {environment} from "../../../../environments/environment";
import {SnackbarType} from "../../snackbar/snackbar-type";
import {SnackbarService} from "../../../services/snackbar/snackbar.service";

@Component({
	selector: "app-checkout-dialog",
	templateUrl: "./checkout-dialog.component.html",
	styleUrls: ["./checkout-dialog.component.css"]
})
export class CheckoutDialogComponent implements OnInit {
	productBo: ProductListBO = {
		name: "",
		barcode: "",
		imageUrls: [],
		sku: "",
		quantity: "0",
		minQuantity: 2,
		existStatus: ExistStatus.EXIST_GREEN
	};
	public productList: Observable<ProductListBO[]> = of([]);
	public productListStatic: ProductListBO[] = [];
	public allExistStatus = ExistStatus;
	public unknownAvailable: Observable<boolean> = of(false);
	public emptyList: Observable<boolean> = of(false);

	constructor(
		public dialogRef: MatDialogRef<CheckoutDialogComponent>,
		private store: Store<AppState>,
		private basketService: BasketService,
		private snackbarService: SnackbarService,
	) {
	}

	ngOnInit(): void {
		this.productList = this.store.select(selectProductList);
		this.productList.subscribe((list: ProductListBO[]): any => this.productListStatic = list);
		this.unknownAvailable = this.store.select(selectAllProductExist);
	}

	public close(): void {
		this.dialogRef.close();
	}

	public openBasket(): void {
		this.store.dispatch(startSpinner());
		let windowTab = window.open("", "_blank");
		windowTab.document.write("<html>\n" +
			"<head><title>Bitte warten Sie...</title></head>\n" +
			"<style>\n" +
			"\tbody {\n" +
			"\t\tdisplay: flex;\n" +
			"\t\tflex-direction: column;\n" +
			"\t\tjustify-content: center;\n" +
			"\t\talign-items: center;\n" +
			"\t\ttext-align: center;\n" +
			"\t\tmargin-top: 20px;\n" +
			"\t\tfont-family: lato, sans-serif;\n" +
			"\t\tfont-weight: 700;\n" +
			"\t\tfont-style: normal;\n" +
			"\t}\n" +
			"\timg {\n" +
			"\t\twidth: 250px;\n" +
			"\t\tmargin-bottom: 20px;\n" +
			"\t}\n" +
			"</style>\n" +
			"<body>\n" +
			"<img alt=\"Redirect Icon\" src=\"assets/redirect-icon.png\">\n" +
			"<h3>Bitte warten Sie, während wir Sie zum VBH Shop weiterleiten...</h3>\n" +
			"</body>\n" +
			"</html>");
		windowTab.document.close();
		this.basketService.addProductsToShopBasket(this.productListStatic).subscribe({
			next: (): any => {
				windowTab.location = environment.pipeUrl + "/VBHDE-VBH_24-Site/de_DE/-/EUR/ViewCart-View";
				this.store.dispatch(stopSpinner());
				this.dialogRef.close();
				this.snackbarService.openSnackbar(SnackbarType.ADD_TO_BASKET);
			},
			error: (type: SnackbarType): any => {
				windowTab.document.write("<html><head></head><body>Error, check scanner app for details</body></html>");
				windowTab.document.close();
				this.store.dispatch(stopSpinner());
				this.snackbarService.openSnackbar(type);
			}
		});


	}
}
