<div [formGroup]="parentForm" class="row custom-row">
	<button (click)="minusQuantity()"
			[disabled]="this.isMinimum"
			class="btn quantity-btn btn-number col-3"
			data-test="minus-quantity"
			matRipple
			type="button">
		<span class="glyphicon glyphicon-minus"></span>
	</button>
	<div class="form-group col-6">
		<input (input)="onInput($event)"
			   class="form-control quantity-input"
			   data-test="quantity-input"
			   formControlName="quantityFormInput"
			   id="quantityFormInput"
			   inputmode="numeric"
			   min="{{minQuantity}}"
			   required
			   step="{{minQuantity}}"
			   type="text"
		>
	</div>
	<button (click)="plusQuantity()"
			[disabled]="this.isMaximum"
			class="btn quantity-btn btn-number col-3"
			data-test="plus-quantity"
			matRipple
			type="button">
		<span class="glyphicon glyphicon-plus"></span>
	</button>
	<div
		*ngIf="quantityInputControl.quantityFormInput.touched &&
		quantityInputControl.quantityFormInput.invalid"
		class="quantity-alert alert alert-danger"
		data-test="quantity-input-error"
	>
		<div i18n="@@vbh.scanner.quantity.validation.insert.number">
			Bitte geben Sie eine Zahl ein. Oder Ihre eingegebene Menge ist zu groß > 9.999.999.
		</div>
	</div>
	<div
		*ngIf="checkJumpAmount !== undefined && checkJumpAmount.hasJumpAmount && checkJumpAmount.oldQuantity !== undefined"
		class="quantity-alert alert alert-primary">
		Die Menge {{checkJumpAmount.oldQuantity}} wurde an die neue Sprungmenge angepasst.
	</div>
	<div *ngIf="this.jumpAmount" class="quantity-alert alert alert-primary" data-test="quantity-jump-amount-info"
		 i18n="@@vbh.scanner.quantity.validation.jump.amount">
		Ihre Eingabe wird an die mögliche Sprungmenge von {{minQuantity}} angepasst.
	</div>
</div>
