import {Component, OnInit} from "@angular/core";
import {AppSettingsService} from "./app-settings.service";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {DefaultQuantityDialogComponent} from "./default-quantity-dialog/default-quantity-dialog.component";
import {AppSettings} from "./app.settings";
import {SnackbarService} from "../../services/snackbar/snackbar.service";
import {SnackbarType} from "../snackbar/snackbar-type";
import {HeaderType} from "../../shell/shared/header/header-type";
import {headerTyp} from "../../state/product-list.actions";
import {Store} from "@ngrx/store";
import {AppState} from "../../state/state";
import {environment} from "../../../environments/environment";

@Component({
	selector: "app-settings",
	templateUrl: "./settings.component.html",
	styleUrls: ["./settings.component.css"]
})
export class SettingsComponent implements OnInit {
	environment = environment;
	public settings: AppSettings;
	public settingsForm: UntypedFormGroup = new UntypedFormGroup({
		settingsAmountJumpInput: new UntypedFormControl(
			"", Validators.compose(
				[
					Validators.minLength(1),
					Validators.maxLength(9),
					Validators.required,
					Validators.pattern("^[0-9]{1,},{0,1}[0-9]{0,4}$")])),
		chatGptModelControl: new UntypedFormControl(""),
		chatGptPromptControl: new UntypedFormControl(""),
		settingsTemperature: new UntypedFormControl(""),
	});
	public durationInSeconds = 5;
	public num: any;
	private readonly SETTINGS_NAME: string = "settings";

	constructor(
		private settingsService: AppSettingsService,
		private snackBar: MatSnackBar,
		public dialog: MatDialog,
		private snackbarService: SnackbarService,
		private store: Store<AppState>
	) {

	}

	public ngOnInit(): void {
		this.store.dispatch(headerTyp({headerType: HeaderType.SETTINGS}));
		this.settings = this.settingsService.settings;
		let settingData = localStorage.getItem(this.SETTINGS_NAME);
		if (settingData === null) {
			localStorage.setItem(this.SETTINGS_NAME, JSON.stringify(this.settings));
		} else {
			this.settings = JSON.parse(settingData) as AppSettings;
			this.settingsService.settings = this.settings;
		}
		this.settingsForm.controls.settingsAmountJumpInput.setValue(this.settings.defaultAmount);

		this.settingsForm.controls.settingsTemperature.setValue(this.settings.chatGptTemperature);
		this.settingsForm.controls.chatGptPromptControl.setValue(this.settings.chatGptPromptKey);
		this.settingsForm.controls.chatGptModelControl.setValue(this.settings.chatGptModel);
	}

	public get m(): any {
		return this.settingsForm.controls;
	}

	/**
	 * Saves the input default quantity in the localstorage
	 */
	public save(): void {
		this.num = Number(this.settingsForm.get("settingsAmountJumpInput")?.value);
		if (Number.isNaN(this.num) || this.num == undefined) {
			return;
		}

		this.settings.defaultAmount = this.num;

		let chatGptTemperatureNum = Number(this.settingsForm.get("settingsTemperature")?.value);
		if (Number.isNaN(chatGptTemperatureNum) || chatGptTemperatureNum == undefined) {
			return;
		}

		this.settings.chatGptModel = this.settingsForm.get("chatGptModelControl")?.value;
		this.settings.chatGptPromptKey = this.settingsForm.get("chatGptPromptControl")?.value;
		this.settings.chatGptTemperature = chatGptTemperatureNum;

		this.settingsService.settings = this.settings;
		localStorage.setItem(this.SETTINGS_NAME, JSON.stringify(this.settings));
		this.settingsForm.markAsPristine();
		this.snackbarService.openSnackbar(SnackbarType.SETTINGS_SUCCESS);
	}

	public openDialog(): void {
		this.dialog.open(DefaultQuantityDialogComponent);
	}
}
