export enum SnackbarType {
	// SETTING_UPDATE = $localize`:@@vbh.scanner.update.list:Die Artikelliste wurde aktualisiert!`,
	NO_BASKET,
	NO_PRODUCT,
	NO_AUTH_TOKEN,
	ADD_TO_BASKET,
	EDIT_NO_PRODUCT,
	EDIT_SUCCESS_PRODUCT,
	EDIT_UPDATE_LIST,
	ADD_TO_LIST,
	ADD_TO_LIST_MAXIMUM,
	SETTINGS_SUCCESS,
	JUMP_AMOUNT_INFO,
	NO_GREEN_PRODUCT
}
