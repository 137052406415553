import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "app-default-quantity-dialog",
	templateUrl: "./default-quantity-dialog.component.html",
	styleUrls: ["./default-quantity-dialog.component.css"]
})
export class DefaultQuantityDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<DefaultQuantityDialogComponent>
	) {
	}

	public closeInfobox(): void {
		this.dialogRef.close();
	}

}
