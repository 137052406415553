import {Component, ElementRef, ViewChild} from "@angular/core";
import {BottomSheetMainComponent} from "../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {Store} from "@ngrx/store";
import {AppState} from "../../state/state";
import {headerTyp} from "../../state/product-list.actions";
import {HeaderType} from "../../shell/shared/header/header-type";
import {Barcode, BarcodePicker, CameraSettings, ScanSettings} from "scandit-sdk";
import {ProductFacade} from "../../facades/product/product.facade";

@Component({
	selector: "app-scan-field",
	templateUrl: "./scan-field.component.html",
	styleUrls: ["./scan-field.component.css"]
})
export class ScanFieldComponent {
	public doScan = true;
	@ViewChild("barcodePicker") barcodePickerElement: ElementRef<HTMLDivElement & { barcodePicker: BarcodePicker; }> | undefined;

	public width: number = 0;
	public height: number = 0;
	public activeSettings: ScanSettings;
	public settingsEAN8: ScanSettings;
	public settingsEAN13: ScanSettings;
	public settingsUPCE: ScanSettings;
	public settings128: ScanSettings;
	public setSearchArea: ScanSettings;
	public cameraSettings: CameraSettings;
	public videoFitContain: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.CONTAIN;
	public videoFitCover: BarcodePicker.ObjectFit = BarcodePicker.ObjectFit.COVER;
	public videoFit: BarcodePicker.ObjectFit = this.videoFitCover;

	constructor(
		private _bottomSheet: MatBottomSheet,
		private productFacade: ProductFacade,
		private store: Store<AppState>,
	) {
		this.store.dispatch(headerTyp({headerType: HeaderType.SCAN}));

		this.activeSettings = new ScanSettings({
			enabledSymbologies: [Barcode.Symbology.EAN8, Barcode.Symbology.EAN13, Barcode.Symbology.UPCE, Barcode.Symbology.CODE128]
		});

		let searchArea = {width: 1, height: 0.3, x: 0, y: 0.3};
		this.activeSettings.setSearchArea(searchArea);

		this.cameraSettings = {
			resolutionPreference: CameraSettings.ResolutionPreference.FULL_HD
		};
	}

	/**
	 * Get called when code is scanned.
	 * @param scanResult
	 */
	public onScan(scanResult: any): void {
		if (this.doScan) {
			this.doScan = false;
			scanResult.barcodes.reduce((result, barcode): any => {
				const bottomSheetRef = this._bottomSheet.open(BottomSheetMainComponent);
				this.productFacade.setProduct(barcode.data, bottomSheetRef.instance);
				bottomSheetRef.afterDismissed().subscribe((): void => {
					this.doScan = true;
				});
			}, "");
		}
	}
}
