import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Product} from "../model/product/product";
import {catchError, concatMap, map, Observable, of} from "rxjs";
import {ProductBO} from "../model/product/product-bo";
import {environment} from "../../../environments/environment";
import {ProductErrorHandler} from "../error-handling/product-error-handler";
import {ProductImage} from "../model/product/product-image";
import {ExistStatus} from "./exist-status";
import {ProductListBO} from "../../components/product-scan-list/product-list-bo.model";
import {ProductQuantityService} from "../quantity/product-quantity.service";
import {CheckJumpAmount} from "../../components/product-scan-list/check-jump-amount";
import {VariationBO} from "../model/product/variation-bo";
import {ProductVariableVariation} from "../model/product/product-variable-variation";
import {Variation} from "../model/product/variation/variation";
import {ProductVariationBO} from "../model/product/variation/product-variation-bo";
import {VariationElement} from "../model/product/variation/variation-element";
import {ProductSearchItem} from "../model/product/product-search-item";

@Injectable({
	providedIn: "root"
})
export class ProductService {
	public urlProduct: string = environment.baseUrl + "/products";

	constructor(
		private http: HttpClient,
		private errorHandler: ProductErrorHandler,
		private quantityService: ProductQuantityService
	) {
	}
	public getProductBO(barcode: string): Observable<ProductBO> {
		barcode = barcode.replace(/ /g,"");
		return this.getProductInformationViaSearchTerm(barcode).pipe(
			concatMap((sku: string): Observable<ProductBO> => {
				return this.getProductInformation(sku);
			}));

	}

	public getProduct(productSKU: string): Observable<Product> {
		let url: string = environment.restUrl + `/${productSKU}`;
		return this.http.get<Product>(url);
	}
	/**
	 * Gets the product data via the productSKU.
	 * @param sku
	 */
	public getProductInformation(sku: string): Observable<ProductBO> {

		return this.getProduct(sku).pipe(map(
			(product): ProductBO => {
				if (product.images != undefined && product.sku != undefined && product.minOrderQuantity != undefined) {
					let productBo: ProductBO = {
						barcode: "",
						imageUrls: this.getBIGPICImages(product.images),
						name: product.name.toString(),
						sku: product.sku.toString(),
						minOrderQuantity: product.minOrderQuantity,
						defaultCategory: product.defaultCategory.name
					};
					this.setVariationAndMasterSKU(product, productBo);
					return productBo;
				} else {
					// throw new Error("The product data is not complete");
					let productBo: ProductBO = {
						barcode: "",
						imageUrls: [""],
						name: "",
						sku: "",
						minOrderQuantity: 1
					};
					return productBo;
				}
			}),
		catchError((err): Observable<ProductBO> => {
			let productBo: ProductBO = {
				barcode: "",
				imageUrls: [""],
				name: "",
				sku: "",
				minOrderQuantity: 1
			};
			return of(productBo);
			// No Product found no access to server
			// throw this.errorHandler.handleError(err);
		}));
	}

	/**
	 * Gets the product data via the searchTerm
	 * @param searchTerm
	 */
	public getProductInformationViaSearchTerm(searchTerm: string): Observable<any>  {
		let url: string = this.urlProduct + "?searchTerm=" +`${searchTerm}`;
		return this.http.get(url).pipe(
			map((response: ProductSearchItem): string => {
				if(response.elements[0] !== undefined) {
					return response.elements[0].uri;

				} else {
					return "";
				}
			}));
	}

	/**
	 * Gets the product data via the productSKU.
	 * @param product
	 */
	public getProductExist(product: ProductListBO): Observable<ProductListBO> {
		return this.getProductInformationViaSearchTerm(product.barcode).pipe(concatMap(
			(sku:string): any => {return this.getProduct(sku);}),
		map(
			(productBO: Product): ProductListBO => {
				if (productBO.images != undefined && productBO.sku != undefined && productBO.minOrderQuantity != undefined) {


					let quantity = this.quantityService.calculateQuantity(product.quantity, productBO.minOrderQuantity);
					let productListBO: ProductListBO = {
						imageUrls: this.getBIGPICImages(productBO.images),
						minQuantity: productBO.minOrderQuantity,
						name: productBO.name.toString(),
						sku: product.sku.toString(),
						quantity: quantity.value,
						barcode: product.sku,
						checkJumpAmount: {
							oldQuantity: product.quantity,
							hasJumpAmount: quantity.jumpAmount
						} as CheckJumpAmount,
						existStatus: ExistStatus.EXIST_GREEN
					};
					this.setVariationAndMasterSKU(productBO, productListBO);
					return productListBO;
				} else {
					return {
						imageUrls: [],
						minQuantity: 1,
						name: "",
						barcode: product.barcode,
						quantity: product.quantity,
						sku: product.sku,
						existStatus: ExistStatus.NOT_EXIST_RED
					} as ProductListBO;
				}
			}),
		catchError((): Observable<ProductListBO> => {
			let productListBO: ProductListBO = {
				imageUrls: [],
				minQuantity: 1,
				name: "",
				barcode: product.barcode,
				quantity: product.quantity,
				sku: product.sku,
				existStatus: ExistStatus.NOT_EXIST_RED
			};
			return of(productListBO);
		}));
	}

	public getProductVariation(masterSKU: string): Observable<ProductVariationBO[]> {
		let url: string = this.urlProduct + `/${masterSKU}` + "/variations";
		return this.http.get<Variation>(url).pipe(map(
			(variation: Variation): ProductVariationBO[] => {
				if (variation.elements != undefined) {
					return this.createProductVariationList(variation.elements);
				} else {
					throw new Error("The product data is not complete");
				}
			}),
		catchError((err): never => {
			// No Product found no access to server
			throw this.errorHandler.handleError(err);
		}));
	}

	/**
	 * Returns only the images with the BIGPIC format.
	 * @param images
	 */
	public getBIGPICImages(images: ProductImage[]): string[] {
		let bigpicImages: string[] = [];
		const smallPic = "S";
		images.forEach(function (image): void {
			if (image.typeID != smallPic) {
				bigpicImages.push(environment.imageUrl + image.effectiveUrl);
			}
		});
		return bigpicImages;
	}

	private createProductVariationList(variationElements: VariationElement[]): ProductVariationBO[] {
		return variationElements.map((value: VariationElement): ProductVariationBO => {
			let productVariationBo: ProductVariationBO = {uri: value.uri, elements: []};

			if (value.variableVariationAttributeValues != undefined) {
				productVariationBo.elements = value.variableVariationAttributeValues.map((attribute): VariationBO => {
					return {
						value: attribute.value,
						name: attribute.name
					} as VariationBO;
				});
			}
			return productVariationBo;
		});
	}

	private setVariationAndMasterSKU(product: Product, productBo: ProductBO | ProductListBO): void {
		if (product.productMasterSKU != undefined && product.variableVariationAttributes != undefined) {
			productBo.productMasterSKU = product.productMasterSKU;
			productBo.variation = product.variableVariationAttributes.map((value: ProductVariableVariation): VariationBO => {
				return {
					value: value.value,
					name: value.name
				} as VariationBO;
			});
		}
	}
}
