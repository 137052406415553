<div>
	<h1 mat-dialog-title
		class="lato-regular">
		Warenkorb Checkout
	</h1>
</div>
<div mat-dialog-content>
	<p>Möchten Sie die Artikel dem Shop Warenkorb hinzufügen?</p>
</div>
<div class="button-row" mat-dialog-actions>
	<button (click)="close()"
			matRipple
			class="btn secondary-btn btn-lg shadow-sm"
			data-test="close-btn">Weiter scannen
	</button>
	<button (click)="openBasket()"
			matRipple
			class="btn primary-btn btn-lg shadow-sm"
			data-test="close-btn">Zum Warenkorb
	</button>
</div>
