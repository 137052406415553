/**
 * This is a typescript class which contains the code from a js library.
 * https://github.com/fromScratchStudioGr/locale-to-number.js
 */
export class NumberToLocale {
	/**
	 * The `localeMapper` object holds the mapping between
	 * the locale id (lang) and the actual `thousands` and `decimal`
	 * separators. For more information, please do check
	 * https://github.com/fromScratchStudioGr/locale-to-number.js/wiki/Supported-Locales
	 */
	static readonly localeMapper = [
		{
			lang: "de-de",
			thousands: "\\.?",
			decimal: "\\,"
		},
		{
			lang: "de",
			thousands: "\\.?",
			decimal: "\\,"
		},
		{
			lang: "en-us",
			thousands: "\\,?",
			decimal: "\\."
		}
	];
	/**
	 * The `localeMapper` object holds the mapping between
	 * the locale id and the actual `thousands` and `decimal`
	 * separators. For more information, please do check
	 * https://github.com/fromScratchStudioGr/locale-to-number.js/wiki/Supported-Locales
	 */
	static readonly localeMapper2 = {
		"cs": {
			thousands: "[\\u202f\\u00a0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"cs-cz": {
			thousands: "[\\u202f\\u00a0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"da": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"da-dk": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"de": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"de-at": {
			thousands: "[\\u202f\\u00a0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"de-ch": {
			thousands: "['΄’]?",
			decimal: "\\."
		},
		"de-de": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"en": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-au": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-ca": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-gb": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-ie": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-in": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"en-nz": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-us": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"en-za": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"es": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"es-ar": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"es-cl": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"es-co": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"es-es": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"es-mx": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"es-us": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"fi": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"fi-fi": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"el": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"el-gr": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"fr": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"fr-be": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"fr-ca": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"fr-ch": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"fr-fr": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"hi": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"hi-in": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"hu": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"hu-hu": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"id": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"id-id": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"it": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"it-it": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"it-ch": {
			thousands: "['΄’]?",
			decimal: "\\."
		},
		"jp": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"jp-jp": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"ko": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"ko-kr": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"nl": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"nl-be": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"nl-nl": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"no": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"no-no": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"pl": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"pl-pl": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"pt": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"pt-br": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"pt-pt": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"ro": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"ro-ro": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"ru": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"ru-ru": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"sk": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"sk-sk": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"sv": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"sv-se": {
			thousands: "[\\u202F\\u00A0\\u2000\\u2001\\u2003\\s]?",
			decimal: "\\,"
		},
		"ta": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"ta-in": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"ta-lk": {
			thousands: "\\,?",
			decimal: "\\.",
			indianNotation: true
		},
		"th": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"th-tr": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"tr": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"tr-tr": {
			thousands: "\\.?",
			decimal: "\\,"
		},
		"zh": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"zh-cn": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"zh-hk": {
			thousands: "\\,?",
			decimal: "\\."
		},
		"zh-tw": {
			thousands: "\\,?",
			decimal: "\\."
		}
	};

	/**
	 * Gets the actual decimal literal of the given number.
	 * @param {string} numberRepresentation - The initial number representation.
	 * @param {string} locale - The locale id in a string format.
	 * @returns The number in a decimal literal format.
	 */
	static getNumber(numberRepresentation: any, locale: string): number | null {
		if (locale == null) {
			console.warn("NTL-error: Locale must be specified");
			return null;
		}
		let localeConfiguration = this.localeMapper.find((o): any => o.lang === locale.toLowerCase());
		if (localeConfiguration != null) {
			const regex = this.constructExtractionRegex(localeConfiguration.thousands, localeConfiguration.decimal, false);
			const cleanNumber = this.cleanNumberRepresentation(numberRepresentation, regex, localeConfiguration);
			return cleanNumber != null ? parseFloat(cleanNumber) : null;
		} else {
			console.log("NTL-error: error");
			return null;
		}
	}

	/**
	 * Constructions the regular expression that can be used to match
	 * number which use the given thousands and decimal separators.
	 * @param {string} thousandsSeparator - The thousands separator.
	 * @param {string} decimalSeparator - The decimal separator.
	 * @param {boolean} indianNotation - Indicates if the Indian notation
	 * must be used.
	 * @returns {RegExp} The regular expression that matches number which
	 * use the given thousands and decimal separators.
	 */
	static constructExtractionRegex(thousandsSeparator: string, decimalSeparator: string, indianNotation = false): RegExp {
		if (indianNotation)
			return new RegExp(`^[\+\-\−]?(([1-9]{1}[0-9]{0,1}${thousandsSeparator})+([0-9]{2}${thousandsSeparator})*[0-9]{3}|[1-9]{1}[0-9]{0,2}|0)(${decimalSeparator}[0-9]+)?$`);
		else
			return new RegExp(`^[\+\-\−]?([1-9]{1}[0-9]{0,2}(${thousandsSeparator}[0-9]{3})*|0)(${decimalSeparator}[0-9]+)?$`);
	}

	/**
	 * Cleans the number representation in order to match
	 * the decimal literal format.
	 * @param {string} numberRepresentation - The initial number representation.
	 * @param {RegExp} regex - The regular expression that corresponds to `numberRepresentation`'s locale.
	 * @param {Object} localeConfiguration - The locale configuration.
	 * @returns The representation of the number in a string format.
	 */
	static cleanNumberRepresentation(numberRepresentation: string, regex: RegExp, localeConfiguration: any): null | string {
		numberRepresentation = numberRepresentation.trim();
		if (numberRepresentation.match(regex)) {
			// Removing the `thousands` separator and making
			// sure the `decimal` separator is the `dot`.
			return numberRepresentation.replace(new RegExp(localeConfiguration.thousands, "g"), "").replace(new RegExp(localeConfiguration.decimal), ".")// This is needed because the `−` characters breaks the
				// `parseFloat` function.
				.replace(new RegExp("\−"), "-");
		} else {
			console.warn("NTL-error: The number representation does match the given locale.");
			return null;
		}
	}
}
