import {Component, Input} from "@angular/core";
import {BottomSheetComponent} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-component";
import {BottomSheetMainComponent} from "../../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {BottomSheetData} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/data/bottom-sheet-data";
import {MatDialog} from "@angular/material/dialog";
import {CodeExplanationDialogComponent} from "./code-explanation-dialog/code-explanation-dialog.component";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {startSpinner} from "../../../state/product-list.actions";
import {ProductFacade} from "../../../facades/product/product.facade";

@Component({
	selector: "app-bottom-sheet-manual-input",
	templateUrl: "./bottom-sheet-manual-input.component.html",
	styleUrls: ["./bottom-sheet-manual-input.component.css"]
})
export class BottomSheetManualInputComponent implements BottomSheetComponent {
	@Input() data: BottomSheetData = {};
	public error: boolean = false;
	public errorMessage: string = "";
	public formValidator: UntypedFormGroup;

	constructor(
		public bottomSheetMainComponent: BottomSheetMainComponent,
		public dialog: MatDialog,
		private formBuilder: UntypedFormBuilder,
		private productFacade: ProductFacade,
		private store: Store<AppState>
	) {
		this.formValidator = formBuilder.group({
			skuInput: ["", [Validators.required]]
		});
	}

	public get form(): any {
		return this.formValidator.controls;
	}

	/**
	 * Handles the submitted sku input.
	 */
	public search(): void {
		this.store.dispatch(startSpinner());
		this.productFacade.setProduct(this.formValidator.value.skuInput, this.bottomSheetMainComponent);
	}

	/**
	 * Open the Code Explanation Dialog to explain the allowed numbers.
	 */
	public openDialog(): void {
		this.dialog.open(CodeExplanationDialogComponent);
	}

	/**
	 * Go back to rescan another product.
	 */
	public rescan(): void {
		this.bottomSheetMainComponent.closeBottomSheet();
	}
}

