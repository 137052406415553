import {Directive, ViewContainerRef} from "@angular/core";

@Directive({
	selector: "[appBottomSheet]"
})
export class BottomSheetDirective {

	constructor(public viewContainerRef: ViewContainerRef) {
	}
}
