import {Component} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";

@Component({
	selector: "app-code-explanation-dialog",
	templateUrl: "./code-explanation-dialog.component.html",
	styleUrls: ["./code-explanation-dialog.component.css"]
})
export class CodeExplanationDialogComponent {
	constructor(
		public dialogRef: MatDialogRef<CodeExplanationDialogComponent>,
	) {
	}

	public closeInfobox(): void {
		this.dialogRef.close();
	}

}
