import {Component, Input} from "@angular/core";
import {BottomSheetData} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/data/bottom-sheet-data";
import {BottomSheetMainComponent} from "../../bottom-sheet-core/bottom-sheet-main/bottom-sheet-main.component";
import {BottomSheetType} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-type";
import {BottomSheetComponent} from "../../bottom-sheet-core/bottom-sheet-dynamic-component/bottom-sheet-component";

@Component({
	selector: "app-bottom-sheet-confirmation-fail",
	templateUrl: "./bottom-sheet-confirmation-fail.component.html",
	styleUrls: ["./bottom-sheet-confirmation-fail.component.css"]
})
export class BottomSheetConfirmationFailComponent implements BottomSheetComponent {
	@Input()
		data: BottomSheetData = {};

	constructor(
		public bottomSheetMainComponent: BottomSheetMainComponent
	) {
	}

	/**
	 * Go back to rescan another product.
	 */
	public rescan(): void {
		this.bottomSheetMainComponent.closeBottomSheet();
	}

	/**
	 * Opens the Manual Input Bottom Sheet.
	 */
	public toManualInput(): void {
		this.bottomSheetMainComponent.loadComponent(BottomSheetType.MANUAL_INPUT);
	}
}
