<div class="container">
	<form [formGroup]="settingsForm" novalidate>
		<div class="edit-container">
			<div class="form-group">
				<label i18n="@@vbh.scanner.settings.default.quantity">Standardmenge
					<span (click)="openDialog()" class="question-icon bi bi-question-circle"
						  data-test="help-dialog"></span>
				</label>
				<input class="form-control input-number"
					   data-test="jump-amount-input"
					   formControlName="settingsAmountJumpInput"
					   inputmode="numeric"
					   type="numeric"
				>
				<div
					*ngIf="m.settingsAmountJumpInput.touched &&
					m.settingsAmountJumpInput.invalid && m.settingsAmountJumpInput.errors?.pattern"
					class="alert alert-danger"
					data-test="quantity-input-error">
					<div i18n="@@vbh.scanner.settings.validation.insert.number">Bitte
						geben Sie eine Zahl ein z.B. "300,12"
					</div>
				</div>
			</div>

			<div class="form-group">
				<label>
					Prompt
				</label>
				<div>
					<mat-form-field appearance="outline" class="w-100">
						<mat-select formControlName="chatGptPromptControl">
							<ng-container *ngFor="let data of ['handwerkerShopping', 'handwerkerShopping2']">
								<mat-option [value]="data">
									{{data}}
								</mat-option>
							</ng-container>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div class="form-group">
				<label>
					Chatgpt Model
				</label>
				<div>
					<mat-form-field appearance="outline" class="w-100">
						<mat-select formControlName="chatGptModelControl">
							<ng-container *ngFor="let data of ['3.5turbo', '4turbo']">
								<mat-option [value]="data">
									{{data}}
								</mat-option>
							</ng-container>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
			<div class="form-group">
				<label i18n="@@vbh.scanner.settings.default.quantity">
					Temperature
				</label>
				<div>
					<input class="form-control input-number"
						   data-test="jump-amount-input"
						   formControlName="settingsTemperature"
						   inputmode="numeric"
						   type="numeric"
					>
				</div>
			</div>

			<button (click)="save()"
					[disabled]="!settingsForm.valid || this.settingsForm.pristine"
					matRipple
					class="btn primary-btn btn-lg btn-block shadow-sm"
					data-test="saveBtn"
					i18n="@@vbh.scanner.settings.save">Speichern
			</button>
		</div>
	</form>
	<div class="version lato-light">App Version: {{ environment.appVersion }}</div>
</div>
