<nav class="navbar fixed-top">
	<a data-placement="bottom"
	   data-toggle="tooltip"
	   href="{{ environment.storefrontUrl }}"
	   target="_blank"
	   title="Zum VBH24 Shop">
		<img alt="VBH Logo" src="../../../../assets/logo_vbh.png"/>
	</a>
	<span *ngIf="(componentType | async) === type.SCAN" class="navbar-brand mx-auto lato-bold">Scannen</span>
	<span *ngIf="(componentType | async) === type.ARTICLE_LIST"
		  class="navbar-brand mx-auto lato-bold">Artikelliste</span>
	<span *ngIf="(componentType | async) === type.SETTINGS" class="navbar-brand mx-auto lato-bold">Einstellungen</span>
	<span (click)="openBasket()" class="bi bi-cart"></span>
</nav>
