import {Component} from "@angular/core";
import {HeaderType} from "./header-type";
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {AppState} from "../../../state/state";
import {selectHeaderTypeScan} from "../../../state/product-list.selectors";
import {environment} from "../../../../environments/environment";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
	environment = environment;
	type = HeaderType;
	public componentType: Observable<HeaderType> = of(HeaderType.DEFAULT);

	constructor(
		private store: Store<AppState>,
	) {
		this.componentType = this.store.select(selectHeaderTypeScan);
	}

	public openBasket(): void {
		window.open(environment.pipeUrl + "/VBHDE-VBH_24-Site/de_DE/-/EUR/ViewCart-View", "_blank");
	}
}
