import {Injectable} from "@angular/core";
import {ProductListBO} from "../../components/product-scan-list/product-list-bo.model";
import {ProductService} from "./product.service";
import {ProductQuantityService} from "../quantity/product-quantity.service";
import {Observable, of} from "rxjs";
import {Store} from "@ngrx/store";
import {addProduct, checkProduct, deleteProduct, editProduct, stopSpinner} from "../../state/product-list.actions";
import {AppState} from "../../state/state";

@Injectable({
	providedIn: "root"
})
export class ProductListService {
	public productList: Observable<ProductListBO[]> = of([]);
	private readonly LIST_NAME: string = "productListBo";

	constructor(
		private productService: ProductService,
		private quantityService: ProductQuantityService,
		private store: Store<AppState>
	) {
	}

	/**
	 * Add product to the localstorage
	 * @param productListBo Product that should be added
	 * @param list
	 */
	public addProduct(productListBo: ProductListBO, list: ProductListBO[]): boolean {
		let pro: ProductListBO | undefined = list.find((product: ProductListBO): any => product.sku === productListBo.sku);

		if (pro === undefined) {
			this.store.dispatch(addProduct({product: productListBo}));
			this.store.dispatch(stopSpinner());
			return false;
		}
		let quantity = this.quantityService.addQuantity(productListBo.quantity, pro.quantity, productListBo.minQuantity);
		productListBo.quantity = quantity.value;
		this.store.dispatch(editProduct({product: productListBo, oldSku: productListBo.sku}));
		this.store.dispatch(stopSpinner());
		return quantity.isMaximum;
	}

	/**
	 * Get list of the products from the localstorage
	 * @return Return list of products
	 */
	public getProductList(): ProductListBO[] {
		let localStorageList = localStorage.getItem(this.LIST_NAME);
		if (localStorageList == null) {
			return [];
		}
		return JSON.parse(localStorageList);
	}

	/**
	 * Delete product from the localstorage
	 * @param sku Product sku that should be removed
	 */
	public deleteProduct(sku: string): void {
		this.store.dispatch(deleteProduct({sku: sku}));
		this.store.dispatch(stopSpinner());
	}


	/**
	 * Edit product from the localstorage
	 * @param oldSku Sku of the current product
	 * @param editBo Product that should be replaced the current product
	 * @param list
	 */
	public editProduct(oldSku: string, editBo: ProductListBO, list: ProductListBO[]): boolean {
		let product = list.find((product: ProductListBO): any => product.sku === editBo.sku);
		if (product === undefined || oldSku === editBo.sku) {
			this.store.dispatch(editProduct({product: editBo, oldSku: oldSku}));
			this.store.dispatch(stopSpinner());
			return false;
		}
		let quantity = this.quantityService.addQuantity(product.quantity, editBo.quantity, product.minQuantity);
		editBo.quantity = quantity.value;

		this.store.dispatch(editProduct({product: editBo, oldSku: editBo.sku}));
		this.store.dispatch(deleteProduct({sku: oldSku}));
		this.store.dispatch(stopSpinner());
		return quantity.isMaximum;
	}

	/**
	 * Check if all products in the localstorage list exist on vbh
	 */
	public checkProductsExist(): any {
		this.store.dispatch(checkProduct({list: this.getProductList()}));
	}
}
