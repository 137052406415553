import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ProductListBO} from "../../components/product-scan-list/product-list-bo.model";
import {ElementBasket} from "../model/basket/elementBasket";
import {QuantityBasket} from "../model/basket/quantityBasket";
import {ExistStatus} from "../product/exist-status";
import {ProductQuantityService} from "../quantity/product-quantity.service";
import {ProductListService} from "../product/product-list.service";
import {SnackbarService} from "../snackbar/snackbar.service";
import {SnackbarType} from "../../components/snackbar/snackbar-type";
import {Store} from "@ngrx/store";
import {AppState} from "../../state/state";
import {stopSpinner} from "../../state/product-list.actions";
import {catchError, concatMap, map, Observable, of,} from "rxjs";
import {BasketCreateRes} from "../model/createBasket/basketCreateRes";


@Injectable({
	providedIn: "root"
})
export class BasketService {
	public durationInSeconds = 5;
	private _tokenUrl = environment.pipeUrl + "/VBHDE-VBH_24-Site/de_DE/-/EUR/VBHViewAuthenticationToken-Start";
	private _basketUrl = environment.baseUrl + "/baskets";
	private _httpOption = {
		headers: new HttpHeaders(
			{
				"Content-Type": "application/json",
			}),
		observe: "response" as "body"
	};
	private _httpOptionToken = {
		headers: new HttpHeaders(
			{}),
		withCredentials: true,
		observe: "response" as "body"
	};

	constructor(
		private http: HttpClient,
		private quantityService: ProductQuantityService,
		private listService: ProductListService,
		private snackbarService: SnackbarService,
		private store: Store<AppState>,
	) {
	}

	/**
	 * Get the initial token from the user to identify the basket and put the product into the basket.
	 * If there is no basket it creates a new one.
	 * @param basket Basket that should be put into the user basket
	 */
	public putProductsIntoBasketProcess(basket: ElementBasket[]): Observable<any> {
		// Get token
		return this.http.get(this._tokenUrl, this._httpOptionToken).pipe(
			catchError((): Observable<any> => {
				return of(SnackbarType.NO_AUTH_TOKEN);
			}),
			map((res: any): any => {
				this._httpOption = {
					headers: new HttpHeaders(
						{
							"Content-Type": "application/json",
							"authentication-token": res.body["authentication-token"]
						}),
					observe: "response" as "body"
				};
				return res.body;
			}),
			concatMap((): any => {
				return this.getBasket();
			}),
			catchError((): Observable<any> => {
				return of(SnackbarType.NO_BASKET);
			}),
			map((res: any): any => {
				this._httpOption = {
					headers: new HttpHeaders(
						{
							"Content-Type": "application/json",
							"authentication-token": res.headers.get("authentication-token")
						}),
					observe: "response" as "body"
				};
				return res;
			}),
			concatMap((response: any): any => {
				if (response.body.elements[0] === undefined) {
					return this.createBasket();
				} else {
					return of(response);
				}
			}),
			catchError((): Observable<any> => {
				return of(SnackbarType.NO_BASKET);
			}),
			map((res: any): any => {
				this._httpOption = {
					headers: new HttpHeaders(
						{
							"Content-Type": "application/json",
							"authentication-token": res.headers.get("authentication-token")
						}),
					observe: "response" as "body"
				};
				const bodyResult = res.body as BasketCreateRes;

				if (res.body !== undefined && res.body.elements !== undefined   && res.body.elements[0] !== undefined && res.body.elements[0].title !== undefined) {
					return res.body.elements[0].title;
				}
				return  bodyResult.data.id;
			}),
			catchError((e): Observable<any> => {
				return of(SnackbarType.NO_BASKET);
			}),
			concatMap((basketId: any): any => {
				return this.postProductsIntoBasket(basketId, basket);
			}),
			catchError((): Observable<any> => {
				return of(SnackbarType.NO_PRODUCT);
			}),
			map((): any => {
				basket.forEach((e: ElementBasket): void => {
					this.listService.deleteProduct(e.product);
				});
			}));
	}

	public addProductsToShopBasket(productList: ProductListBO[]): Observable<any> {
		let basket: ElementBasket[] = this.createBasketWithProducts(productList);
		if (basket.length === 0) {
			this.store.dispatch(stopSpinner());
			this.snackbarService.openSnackbar(SnackbarType.NO_GREEN_PRODUCT);
			return;
		}
		return this.putProductsIntoBasketProcess(basket);
	}

	/**
	 * Calls the actual basket that the user is using
	 * "elements": [{
	 *  "type": "Link",
	 *  "uri": "VBHDE-VBH_24-Site/-/baskets/OKoKCh4EQfsAAAGDKCsVXTWo",
	 *  "title": "OKoKCh4EQfsAAAGDKCsVXTWo"
	 * }]
	 * @private
	 */
	private getBasket(): Observable<any> {
		return this.http.get(this._basketUrl, this._httpOption);
	}
	/**
	 * Put the basket with the articles into the shop basket
	 * @param basketId
	 * @param basket
	 * @private
	 */
	private postProductsIntoBasket(basketId: string, basket: ElementBasket[]): Observable<any> {
		let basketHeaders = {
			headers: new HttpHeaders(
				{
					"Content-Type": "application/json",
					// Check documentation of https://support.intershop.com/kb/index.php/Display/292F90
					"Accept": "application/vnd.intershop.basket.v1+json",
					"authentication-token": this._httpOption.headers.get("authentication-token"),
				}),
			observe: "response" as "body"
		};
		return this.http.post(this._basketUrl + "/" + basketId + "/items", basket, basketHeaders);
	}

	private createBasket(): Observable<any> {
		return this.http.post(this._basketUrl, {}, this._httpOption);
	}


	/**
	 * Create json basket for the rest api with the product list
	 * @param productList List with the current products
	 * @private
	 */
	private createBasketWithProducts(productList: ProductListBO[]): ElementBasket[] {
		let basket: ElementBasket[] = [];
		productList.forEach((e: ProductListBO): void => {
			if (e.existStatus == ExistStatus.EXIST_GREEN) {
				basket.push(this.createBasketElementFromProductListBO(e));
			}
		});
		return basket;
	}

	/**
	 * Creates a basket element from a product list bo
	 * @param product The product list bo
	 * @private
	 */
	private createBasketElementFromProductListBO(product: ProductListBO): ElementBasket {
		let quantityBasket: QuantityBasket = {value: this.quantityService.checkInput(product.quantity), unit:""};
		return {
			product: product.sku,
			quantity: quantityBasket
		};
	}
}
