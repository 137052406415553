<div>
	<h1 mat-dialog-title
		class="lato-regular"
		i18n="@@vbh.scanner.delete.dialog.headline">Artikel löschen
	</h1>
</div>
<div mat-dialog-content>
	<p i18n="@@vbh.scanner.delete.dialog.text">
		Sind Sie sich sicher, dass Sie diesen Artikel aus der
		Artikelliste löschen möchten?
	</p>
</div>
<div mat-dialog-actions>
	<button
		(click)="deleteProduct()"
		matRipple
		data-test="delete"
		class="btn primary-btn btn-lg shadow-sm"
		i18n="@@vbh.scanner.delete.dialog.confirm.deletion">Löschen
	</button>
</div>
