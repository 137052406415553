<div *ngIf="(productBo | json) !== '{}'" class="container">
	<div class="heading-container--stycky">
		<div class="img-container">
			<img (click)="changeImage()"
				 [alt]="productBo.name"
				 [src]="this.imageUrl"
				 data-test="image"
			>
		</div>
		<div class="custom-container">
			<h4 data-test="name">{{productBo.name}}</h4>
			<p class="lato-light" data-test="sku">{{productBo.sku}}</p>
			<div *ngIf="productBo.variation !== undefined">
				<div *ngFor="let variation of productBo.variation">
					<p class="subtitle-variations">{{variation.name}}: {{variation.value}}</p>
				</div>
				<p class="subtitle-variations-info">
					Die Variationen können in der Artikelliste bearbeitet werden.
				</p>
			</div>
			<form [formGroup]="quantityForm" novalidate>
				<app-quantity [minQuantity]="productBo.minOrderQuantity"
							  [parentForm]="quantityForm">
				</app-quantity>
			</form>
		</div>
	</div>
	<div class="ai-suggest-container" [ngClass]="{'ai-suggest-container--scroll': productBos?.length > 1}">
		<h2
			*ngIf="!productBos || productBos?.length > 1"
			class="modal-title lato-bold pb-3" >Weitere Empfehlungen
		</h2>
		<!-- inner spinner -->
		<div class="inner-spinner-container" *ngIf="!productBos">
			<mat-progress-spinner
				mode="indeterminate"
				value="50"
				diameter="200"
				strokeWidth="3"
			>
			</mat-progress-spinner>
			<img src="assets/logo.svg" alt="bild">

		</div>
		<!-- inner spinner -->
		<ng-container *ngFor="let bos of productBos" >
			<label 
				class="suggestion-container d-flex justify-content-between my-4" 
				[ngClass]="{'suggestion-container-checked': isChecked(bos)}" 
				(click)="addToCheckedBos(bos, $event)"
			>
				<div class="d-flex align-items-center">
					<label [ngClass]="{'checkbox-checked-bg': isChecked(bos)}" class="checkbox" >
						<input [ngClass]="{'checked': isChecked(bos)}" type="checkbox">

					</label>
					<div class="suggestion-tile">
						<h3>
							{{ bos.name}}
						</h3>
						<p>
							{{ bos.sku}}
						</p>	
					</div>
				</div>
				<img
				 	[alt]="bos.name"
				 	[src]="bos.imageUrls[0]"
					(error)="bos.imageUrls[0] = 'assets/img-not-available.png'"
				 	data-test="image"
				>
			</label>

		</ng-container>
	</div>

</div>
<div class="modal-footer pt-4 modal-footer--sticky">
	<button (click)="rescan()"
			matRipple
			class="btn secondary-btn btn-lg shadow-sm col-6"
			data-test="rescan"
			i18n="@@vbh.scanner.bs.product.info.scan.again">Erneut scannen
	</button>
	<button (click)="addProductToList()"
			[disabled]="!quantityForm.valid"
			matRipple
			class="btn primary-btn btn-lg shadow-sm col-6"
			data-test="tobasket"
			i18n="@@vbh.scanner.bs.product.info.add.to.article.list"
			type="submit">Zur Artikelliste hinzufügen
	</button>
</div>
