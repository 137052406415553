import {createReducer, on} from "@ngrx/store";
import {
	addProduct,
	checkProduct,
	deleteProduct,
	editProduct, headerTyp,
	initialProductList,
	startSpinner,
	stopSpinner
} from "./product-list.actions";
import {AppState} from "./state";
import {ProductListBO} from "../components/product-scan-list/product-list-bo.model";
import {HeaderType} from "../shell/shared/header/header-type";

/**
 * Der Reducer reagiert auf die Actions und führt dann Code aus. Dieser Code verändert seinen State. Dieser State ist bei uns die Haupotkomponente
 * die verändert werden soll. Indemfall die Liste der Produkte.
 */

const LIST_NAME: string = "productListBo";
export const initialState: AppState = {
	loading: false,
	productList: [],
	spinner: false,
	headerType: HeaderType.DEFAULT
};

export const productReducer = createReducer(
	initialState.productList,
	on(initialProductList, (state, {initialProductList}): ProductListBO[] => {
		return initialProductList;
	}),
	on(addProduct, (state, {product}): ProductListBO[] => {
		state = [...state, product];
		localStorage.setItem(LIST_NAME, JSON.stringify(state));
		return state;
	}),
	on(editProduct, (state, {product, oldSku}): ProductListBO[] => {
		let list = state.map((obj): ProductListBO => {
			if (obj.sku === oldSku) {
				return product;
			}
			return obj;
		});
		localStorage.setItem(LIST_NAME, JSON.stringify(list));
		return list;
	}),
	on(deleteProduct, (state, {sku}): ProductListBO[] => {
		let list = state.filter((obj: ProductListBO): any => obj.sku !== sku);
		localStorage.setItem(LIST_NAME, JSON.stringify(list));
		return list;
	}),
	on(checkProduct, (state, {list}): ProductListBO[] => {
		return state;
	})
);

export const loadingReducer = createReducer("");
export const spinnerReducer = createReducer(
	initialState.spinner,
	on(startSpinner, (state): boolean => {
		return true;
	}),
	on(stopSpinner, (state): boolean => {
		return false;
	}),
);

export const headerReducer = createReducer(
	initialState.headerType,
	on(headerTyp, (state, {headerType}): HeaderType => {
		return headerType;
	}),
);
