import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SnackbarType} from "../../components/snackbar/snackbar-type";
import {SnackbarInfoComponent} from "../../components/snackbar/snackbar-info/snackbar-info.component";

@Injectable({
	providedIn: "root"
})
export class SnackbarService {
	private _durationInSeconds = 3;

	constructor(private snackBar: MatSnackBar) {
	}

	public openSnackbar(type: SnackbarType): void {
		this.snackBar.openFromComponent(SnackbarInfoComponent, {
			data: type,
			duration: this._durationInSeconds * 1000,
			horizontalPosition: "center",
			verticalPosition: "bottom",
		});
	}
}
