import {ApplicationRef, Component, HostListener, OnInit} from "@angular/core";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {filter, interval, map, Observable, of} from "rxjs";
import {OnlineStatusService, OnlineStatusType} from "ngx-online-status";
import {initialProductList} from "./state/product-list.actions";
import {ProductListService} from "./services/product/product-list.service";
import {Store} from "@ngrx/store";
import {AppState} from "./state/state";
import {selectSpinnerIsOn} from "./state/product-list.selectors";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.css"]
})

export class AppComponent implements OnInit {
	public spinner: Observable<boolean> = of(true);
	public title = "vbh-scanner";
	readonly HOUR = 8;
	// Value how often the pwa should call for new updates
	readonly TIME = this.HOUR * 60 * 60 * 1000;

	// readonly TIME = 5000; for development
	public OnlineStatusType = OnlineStatusType;
	public status: OnlineStatusType = this.onlineStatusService.getStatus();

	constructor(
		private update: SwUpdate,
		private appRef: ApplicationRef,
		private onlineStatusService: OnlineStatusService,
		private productListService: ProductListService,
		private store: Store<AppState>
	) {
		this.updateClient();
		this.checkUpdate();
		this.onlineStatusService.status.subscribe((status: OnlineStatusType): void => {
			this.status = status;
		});
	}

	public ngOnInit(): void {
		this.spinner = this.store.select(selectSpinnerIsOn);
	}

	@HostListener("window:load")
	onLoad(): void {
		let productList = this.productListService.getProductList();
		this.store.dispatch(initialProductList({initialProductList: productList}));
	}

	/**
	 * Updates the app if new changes are available
	 */
	public updateClient(): void {
		if (!this.update.isEnabled) {
			return;
		}
		const updatesAvailable = this.update.versionUpdates.pipe(
			filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
			map((evt): any => ({
				type: "UPDATE_AVAILABLE",
				current: evt.currentVersion,
				available: evt.latestVersion,
			})));
		updatesAvailable.subscribe((): any => {
			this.update.activateUpdate().then((): any => location.reload());
		});
	}

	/**
	 * Check in an interval if an update is available
	 */
	public checkUpdate(): void {
		this.appRef.isStable.subscribe((isStable): any => {
			if (isStable) {
				const timeInterval = interval(this.TIME);
				timeInterval.subscribe((): any => {
					this.update.checkForUpdate().then((): any => {
					});
				});
			}
		});
	}
}


