import {Injectable} from "@angular/core";
import {ProductService} from "../services/product/product.service";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import * as ProductAction from "./product-list.actions";
import {editProduct, stopSpinner} from "./product-list.actions";
import {Store} from "@ngrx/store";
import {switchMap} from "rxjs";
import {ProductListBO} from "../components/product-scan-list/product-list-bo.model";

@Injectable()
export class ProductListEffects {

	loadExist$ = createEffect((): any => this.actions$.pipe(
		ofType(ProductAction.checkProduct),
		switchMap((action: any): any => {
			action.list.forEach((product: ProductListBO): any => {
				this.productService.getProductExist(product).subscribe((bo: ProductListBO): any => {
					this.store.dispatch(editProduct({oldSku: bo.sku, product: bo}));
				});
			});
			this.store.dispatch(stopSpinner());
		})
	));

	constructor(private actions$: Actions, private productService: ProductService, private store: Store) {
	}
}
